import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type SaveBreakDetectionParametersCommand = {
    DeviceId: number;
    BreakDetectionEnabled: boolean;
    BreakDetectionMovingAverageWindow?: number | null;
    BreakDetectionAngleDeviation?: number | null;
};

export const saveBreakDetectionParameters = (data: SaveBreakDetectionParametersCommand) => {
    return axios.post(`/devices/${data.DeviceId}/break-detection`, data);
};

type UseSaveBreakDetectionParametersOptions = {
    config?: MutationConfig<typeof saveBreakDetectionParameters>;
};

export const useSaveBreakDetectionParameters = ({ config }: UseSaveBreakDetectionParametersOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка сохранения параметров!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("break-detection");
            showNotification({
                title: t("Успех!"),
                message: t("Параметры успешно сохранены!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: saveBreakDetectionParameters,
    });
};
