import { useMutation } from "react-query";

import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export interface CreateDeviceChannelCommand {
    ChannelId: number;
    DeviceId: number;
}

export const createDeviceChannel = (command: CreateDeviceChannelCommand): Promise<EntityId<number>> => {
    return axios.post(`/devices/${command.DeviceId}/device-channels`, command);
};

type UseCreateDeviceChannelOptions = {
    config?: MutationConfig<typeof createDeviceChannel>;
};

export const useCreateDeviceChannel = ({ config }: UseCreateDeviceChannelOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при создании датчика устройства!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно создан датчик устройства!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createDeviceChannel,
    });
};
