import { Anchor, Breadcrumbs, Group, Stack, Title, Text } from "@mantine/core";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

export type BreadcrumbItem = {
  title: string;
  to: string;
  icon: React.ReactNode;
};

export type PageHeaderProps = {
  title: string;
  description?: string;
  titleIcon?: React.ReactNode;
  breadcrumb?: BreadcrumbItem[];
  actions?: React.ReactNode;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, titleIcon, breadcrumb, actions, description }) => {
  const links = useMemo(
    () =>
      breadcrumb?.map((item) => (
        <Anchor component={Link} to={item.to}>
          <Group spacing="xs" align="center">
            {item.icon}
            {item.title}
          </Group>
        </Anchor>
      )),
    []
  );

  return (
    <Group position="apart">
      <Stack mb="xl" spacing="xs">
        {breadcrumb && <Breadcrumbs>{links}</Breadcrumbs>}
        <Group>
          {titleIcon}
          <Title order={1}>{title}</Title>
        </Group>
        {description && <Text color="dimmed">{description}</Text>}
      </Stack>
      {actions}
    </Group>
  );
};

PageHeader.displayName = "PageHeader";
