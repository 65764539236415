import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import FormContainer from "../../../components/auth/styledFormContainer";
import { Logo } from "../../../components/EnergyLogo";
import { useTranslation } from "react-i18next";
import { SchemaOf } from "yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, Group, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useAuth } from "src/lib/auth";
import { LoginCommand } from "src/features/auth";
import { useChangeSessionLanguage } from "src/features/profile/api/changeSessionLanguage";
import storage from "src/utils/storage";
import { LanguagePicker } from "src/features/language/LanguagePicker";

const LoginSchema: SchemaOf<LoginCommand> = Yup.object({
    Login: Yup.string().required("Email не может быть пустым"),
    Password: Yup.string().required("Пароль не может быть пустым"),
});

interface LoginFormProps {
    onSuccess: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { login, isLoggingIn } = useAuth();

    const setSessionLanguageMutation = useChangeSessionLanguage();

    const [sessionLanguage, setSessionLanguage] = useState<number>(Number(storage.getLanguage() === "ru" ? 1 : storage.getLanguage() === "en" ? 2 : storage.getLanguage() === "tr" ? 3 : storage.getLanguage() === "ir" ? 4 : 1));

    const formik = useFormik<LoginCommand>({
        initialValues: {
            Login: "",
            Password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            await login(values);
            onSuccess();
        },
    });

    useEffect(() => {
        setSessionLanguage(sessionLanguage ? sessionLanguage : 1);
    });

    function handleSessionLanguageChange() {
        setSessionLanguageMutation.mutateAsync({
            LanguageId: Number(sessionLanguage),
        });
    }

    return (
        <FormContainer>
            <LoadingOverlay visible={isLoggingIn} />
            <div className="flex flex-col items-center">
                <Logo />
                <div className="font-medium text-2xl my-8">{t("Вход")}</div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <TextInput
                    id="Login"
                    name="Login"
                    className="mt-1 mb-6"
                    label={t("Email или имя пользователя")}
                    value={formik.values.Login}
                    onChange={formik.handleChange}
                    error={formik.touched.Login && formik.errors.Login}
                />
                <TextInput
                    id="Password"
                    name="Password"
                    className="mt-1 mb-6"
                    type="password"
                    label={t("Пароль")}
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    error={formik.touched.Password && formik.errors.Password}
                />
                <div className="flex flex-col space-y-6 justify-center">
                    <Button type="submit">{t("Войти")}</Button>

                    <Button
                        variant="subtle"
                        onClick={() => {
                            navigate("/register");
                        }}
                    >
                        {t("Зарегистрироваться")}
                    </Button>
                </div>
            </form>
            <div className="space-y-4 flex-auto">
                {t("Язык сессии")}
                <Group>
                    <LanguagePicker onSelectLanguage={setSessionLanguage} language={sessionLanguage} />
                    <Button onClick={handleSessionLanguageChange}>{t("Применить")}</Button>
                </Group>
            </div>
        </FormContainer>
    );
};
