import {
    Paper,
    SegmentedControl,
} from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TITableSettings } from "src/features/iec/components/TITableSettings";
import { TCTableSettings } from "src/features/iec/components/TCTableSettings";
import { ObjectSettings } from "src/features/iec/components/ObjectSettings";
import { useAuth } from "src/lib/auth";


export const IECForm: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [tab, setTab] = useState<string | undefined>("TISettings");

    const tabs = [
        { label: t("Настройка TI"), value: "TISettings" },
        { label: t("Настройка TC"), value: "TCSettings" },
        { label: t("Настройка объектов"), value: "ObjectSettings" },
    ];

    return (
        <React.Fragment>
            <div className="overflow-auto h-full flex-1 p-4 z-50 bg-gray-100">
                <div className="mb-4 font-bold text-2xl">{t("Интеграция по МЭК-104")}</div>
                {user?.IEC && (
                    <div>
                        < Paper className="mb-4" p="md" shadow="sm">
                            <SegmentedControl className="mt-4" value={tab} onChange={setTab} data={tabs} />
                        </Paper>
                        {tab === "TISettings" && <TITableSettings user={user} />}
                        {tab === "TCSettings" && <TCTableSettings user={user} />}
                        {tab === "ObjectSettings" && <ObjectSettings user={user} />}
                    </div>
                )}
                {!user?.IEC && (
                    <div className="mb-4 text-base font-bold text-gray-900">{t("Передача по МЭК выключена, пожалуйста, включите ее в настройках пользователя")}</div>
                )}
            </div>
        </React.Fragment >
    );
};
