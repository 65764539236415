import { useQuery } from "react-query";
import { ShortCircuitEventDto, ShortCircuitEventEnum } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "src/types";

export type GetShortCircuitEventsQuery = PagedRequest & {
    DeviceId: number;
    StartDate: string;
    EndDate: string;
    Events: ShortCircuitEventEnum[];
};

export const getShortCircuits = (query: GetShortCircuitEventsQuery): Promise<PagedCollection<ShortCircuitEventDto>> => {
    return axios.post(`/devices/${query.DeviceId}/short-circuits`, query);
};

type QueryFnType = typeof getShortCircuits;

type UseShortCircuitsOptions = {
    config?: QueryConfig<QueryFnType>;
    query: GetShortCircuitEventsQuery;
};

export const useShortCircuits = ({ query, config }: UseShortCircuitsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [
            "short-circuits",
            query.DeviceId,
            query.StartDate,
            query.EndDate,
            query.Events,
            query.Skip,
            query.Take,
        ],
        queryFn: () => getShortCircuits(query),
        ...config,
    });
};
