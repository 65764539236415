import { Avatar, Text } from "@mantine/core";
import React from "react";
import { UserDto } from "src/features/auth";

type UserInfoProps = {
    user: UserDto;
};

export const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
    return (
        <div>
            <Avatar size={120} radius={120} mx="auto">
                {user.UserName.slice(0, 2)}
            </Avatar>
            <Text ta="center" fz="lg" weight={500} mt="md">
                {user.UserName}
            </Text>
            <Text ta="center" c="dimmed" fz="sm">
                {user.Email}
            </Text>
        </div>
    );
};

UserInfo.displayName = "UserInfo";
