import { TelegramBindingDto } from "src/features/profile";

export interface ChannelThresholdDto {
    Id: number;
    Value: number;
    Description: string;
    Direction: ThresholdDirectionEnum;
    MessageTemplate: string;
    Color: string;
    DeviceChannelId: number;
    ChannelThresholdNotifications: ChannelThresholdNotificationDto[];
}

export interface ChannelThresholdNotificationDto {
    Id: number;
    Enabled: boolean;
    TelegramBinding: TelegramBindingDto | null;
}

export enum ThresholdDirectionEnum {
    Upper,
    Lower,
    Equals,
}
