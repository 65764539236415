import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { BreakDetectionParametersDto } from "../types";

export interface GetBreakDetectionParametersQuery {
    DeviceId: number;
}

export const getBreakDetectionParameters = (
    query: GetBreakDetectionParametersQuery
): Promise<BreakDetectionParametersDto> => {
    return axios.get(`/devices/${query.DeviceId}/break-detection`);
};

type QueryFnType = typeof getBreakDetectionParameters;

type UseBreakDetectionParametersOptions = {
    query: GetBreakDetectionParametersQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useBreakDetectionParameters = ({ query, config }: UseBreakDetectionParametersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["break-detection", query.DeviceId],
        queryFn: () => getBreakDetectionParameters(query),
    });
};
