import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DeviceDto, OscilloscopeDto } from "src/features/devices/types";
import { Button } from "@mantine/core";
import { saveAs } from "file-saver";
import iconv from "iconv-lite";
import { getOscilloscopeFile } from "src/api/oscilloscopeFile";

interface OscilloscopeProps {
    oscilloscopeDto: OscilloscopeDto;
    device: DeviceDto;
    height?: number;
}

const colors = ["#BF2600"];

const Oscilloscope: React.FC<OscilloscopeProps> = ({ oscilloscopeDto, device, height = 480 }) => {
    const { t } = useTranslation();
    const [oscilloscopeOptions, setOscilloscopeOptions] = useState<any>({});
    const [oscilloscopeSeries, setOscilloscopeSeries] = useState<any>([]);

    useEffect(() => {
        const timeStep = 1 / oscilloscopeDto.Frequency;
        const measurementsTimestamp = new Date(oscilloscopeDto.MeasurementsTimestamp);
        const seriesData = oscilloscopeDto.Data.map((value, index) => ({
            x: measurementsTimestamp.getTime() + index * timeStep * 1000,
            y: value,
        }));

        setOscilloscopeOptions({
            chart: {
                type: "line",
                stacked: false,
                id: "oscilloscope",
                zoom: {
                    enabled: true,
                    autoScaleYaxis: false,
                },
            },
            legend: {
                show: false,
            },
            colors: colors,
            stroke: {
                width: 2,
            },
            plotOptions: {
                line: {
                    columnWidth: "20%",
                },
            },
            xaxis: {
                type: "datetime",
                labels: {
                    rotate: -45,
                    formatter: (value: any) => dayjs(value).format("HH:mm:ss.SSS"),
                },
                tooltip: {
                    enabled: false,
                },
                tickAmount: 20,
            },
            yaxis: {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: "#BF2600",
                },
                labels: {
                    style: {
                        colors: "#BF2600",
                    },
                    formatter: (value: string) => {
                        const numberValue = Number(value);
                        return t("A")
                            ? `${Number.isInteger(numberValue) ? value : numberValue.toFixed(2)} ${t("A")}`
                            : numberValue.toFixed(2);
                    },
                },
                title: {
                    text: t("Ток"),
                    style: {
                        color: "#BF2600",
                    },
                },
            },
        });

        setOscilloscopeSeries(seriesData);
    }, [oscilloscopeDto, t]);

    return (
        <div>
            <Chart
                options={oscilloscopeOptions}
                series={[{ data: oscilloscopeSeries }]}
                type="line"
                height={height}
                width="100%"
            />
            {/* <Button
                onClick={() => {
                    const fileSettings = { DeviceId: device.Id, OscilloscopeId: oscilloscopeDto.Id };
                    const filename =
                        device.Name +
                        " " +
                        device.CCID +
                        " " +
                        dayjs(oscilloscopeDto.MeasurementsTimestamp).format("DD.MM.YY HH:mm:ss.SSS") +
                        ".cff";

                    getOscilloscopeFile({ OscilloscopeFileSettings: fileSettings, Filename: filename });
                }}
            >
                Download as COMTRADE
            </Button> */}
        </div>
    );
};

export default Oscilloscope;
