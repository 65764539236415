import dayjs from "dayjs";
import React, { useState } from "react";
import { Card, Group, MantineTheme, MultiSelect, Pagination, Text, ThemeIcon } from "@mantine/core";
import { useShortCircuits } from "src/features/devices/api/getShortCircuits";
import { ShortCircuitEventEnum } from "src/features/devices/types";
import { IconArrowUpRight, IconArrowDownRight, IconCheck } from "@tabler/icons-react";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import { DateRangePicker } from "@mantine/dates";
import { useTranslation } from "react-i18next";

const labels = {
    [ShortCircuitEventEnum.LowCurrent]: "Снижение тока ниже аварийной уставки",
    [ShortCircuitEventEnum.ShortCircuitCurrent]: "Короткое замыкание",
    [ShortCircuitEventEnum.NormalCurrent]: "Нормальное состояние",
};

const icons = {
    [ShortCircuitEventEnum.LowCurrent]: IconArrowDownRight,
    [ShortCircuitEventEnum.ShortCircuitCurrent]: IconArrowUpRight,
    [ShortCircuitEventEnum.NormalCurrent]: IconCheck,
};

function getColor(event: ShortCircuitEventEnum, theme: MantineTheme) {
    switch (event) {
        case ShortCircuitEventEnum.LowCurrent:
            return theme.colors.red[6];
        case ShortCircuitEventEnum.ShortCircuitCurrent:
            return theme.colors.red[6];
        case ShortCircuitEventEnum.NormalCurrent:
            return theme.colors.teal[6];
    }
}

// const data: ShortCircuitEventDto[] = [
//     {
//         Current: 1.1234,
//         Event: ShortCircuitEventEnum.LowCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
//     {
//         Current: 100.1234,
//         Event: ShortCircuitEventEnum.ShortCircuitCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
//     {
//         Current: 100.1234,
//         Event: ShortCircuitEventEnum.ShortCircuitCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
//     {
//         Current: 100.1234,
//         Event: ShortCircuitEventEnum.ShortCircuitCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
//     {
//         Current: 100.1234,
//         Event: ShortCircuitEventEnum.ShortCircuitCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
//     {
//         Current: 100.1234,
//         Event: ShortCircuitEventEnum.ShortCircuitCurrent,
//         Timestamp: "2022-03-22T15:20:24.624Z",
//     },
// ];

const eventsData = [
    {
        value: ShortCircuitEventEnum.LowCurrent.toString(),
        label: "Ниже уставки",
    },
    {
        value: ShortCircuitEventEnum.ShortCircuitCurrent.toString(),
        label: "Короткое замыкание",
    },
    // {
    //     value: ShortCircuitEventEnum.NormalCurrent.toString(),
    //     label: "Норма",
    // },
];

interface Props {}

export const ShortCircuits: React.FC<Props> = () => {
    const selectedDevice = useSelectedDevice();
    const { t } = useTranslation();

    const [activePage, setPage] = useState(1);

    const [events, setEvents] = useState<string[]>([
        ShortCircuitEventEnum.LowCurrent.toString(),
        ShortCircuitEventEnum.ShortCircuitCurrent.toString(),
    ]);

    const [interval, setInterval] = useState<[Date | null, Date | null]>([
        dayjs().subtract(7, "day").toDate(),
        dayjs().toDate(),
    ]);

    const { data, isLoading } = useShortCircuits({
        query: {
            DeviceId: selectedDevice!.Id,
            StartDate: interval[0] ? dayjs(interval[0]).toISOString() : dayjs().subtract(7, "day").toISOString(),
            EndDate: interval[1] ? dayjs(interval[1]).toISOString() : dayjs().toISOString(),
            Events: events.map((x) => Number(x)),
            Skip: (activePage - 1) * 20,
            Take: 20,
        },
        config: {
            enabled: !!interval[0] && !!interval[1],
        },
    });

    return (
        <React.Fragment>
            {selectedDevice && (
                <>
                    <Card shadow="md">
                        <Group mb="md">
                            <DateRangePicker
                                className="w-52"
                                inputFormat="DD.MM.YYYY"
                                label={t("Период")}
                                value={interval}
                                onChange={setInterval}
                            />
                            <MultiSelect value={events} onChange={setEvents} data={eventsData} label={t("События")} />
                        </Group>
                    </Card>

                    <div className="flex-1 p-0 md:p-4">
                        {data && data.Page.length ? (
                            <Group my="md" position="right">
                                <Pagination
                                    page={activePage}
                                    onChange={setPage}
                                    total={Math.floor(data.Total / 20)}
                                    withEdges
                                />
                            </Group>
                        ) : null}
                        {data &&
                            data.Page.map((x) => {
                                const DiffIcon = icons[x.Event];
                                return (
                                    <Card shadow="xs" p="md" radius="md" mb="md" key={x.Timestamp}>
                                        <Group position="apart">
                                            <div>
                                                <Text color="dimmed" transform="uppercase" weight={700} size="xs">
                                                    {labels[x.Event]}
                                                </Text>
                                                <Text weight={700} size="xl">
                                                    {x.Current} A
                                                </Text>
                                            </div>
                                            <ThemeIcon
                                                color="gray"
                                                variant="light"
                                                sx={(theme) => ({
                                                    color: getColor(x.Event, theme),
                                                })}
                                                size={38}
                                                radius="md"
                                            >
                                                <DiffIcon size={28} />
                                            </ThemeIcon>
                                        </Group>
                                        <Text color="dimmed" size="sm" mt="sm">
                                            {dayjs(x.Timestamp).format("DD.MM.YY HH:mm.SSS")}
                                        </Text>
                                    </Card>
                                );
                            })}
                        {data && data.Page.length ? (
                            <Group my="md" position="right">
                                <Pagination
                                    page={activePage}
                                    onChange={setPage}
                                    total={Math.floor(data.Total / 20)}
                                    withEdges
                                />
                            </Group>
                        ) : null}
                    </div>
                </>
            )}
        </React.Fragment>
    );
};
