import React from "react";
import { useTranslation } from "react-i18next";

export const InfoForm: React.FC = ({ }) => {
    const { t } = useTranslation();

    return (
        <>
            <form className="flex flex-col flex-1 bg-white overflow-auto">
                <div className="p-4 mb-4 flex-1 max-w-6xl">
                    <div className="mb-4 font-bold text-2xl">{t("Программа модуля дистанционной диагностики линий электропередач E-Energy")}</div>
                    <div className="mb-4 font-bold text-xl">{t("ООО «СервисЭнерджи»")}</div>
                    <div className="mb-4 text-base font-bold text-gray-900">{t("Юридический адрес")} {": "}  {t("115201, г. Москва, проезд Каширский, д. 13, помещ. XIII, этаж 2, ком. 33")}</div>
                </div>
            </form>
        </>
    );
};
