import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { ChannelTCDto } from "../types";

export interface GetIECChannelTCQuery extends PagedRequest {
    UserId: number;
}

export const getIECChannelTC = (request: GetIECChannelTCQuery): Promise<PagedCollection<ChannelTCDto>> => {
    return axios.post(`/IEC/${request.UserId}/channeltc`, request);
};

type QueryFnType = typeof getIECChannelTC;

type UseIECChannelTCOptions = {
    request: GetIECChannelTCQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useIECChannelTC = ({ request, config }: UseIECChannelTCOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["IEC/{UserId}/channeltc", request],
        queryFn: () => getIECChannelTC(request),
    });
};
