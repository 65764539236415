import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { LanguageEnum } from "src/features/auth";

export type ChangeSessionLanguageCommand = {
    LanguageId: number;
};

export const changeSessionLanguage = (data: ChangeSessionLanguageCommand): Promise<EntityId<number>> => {
    return axios.post(`/users/change-sessionlanguage`, data);
};

type UseChangeSessionLanguageOptions = {
    config?: MutationConfig<typeof changeSessionLanguage>;
};

export const useChangeSessionLanguage = ({ config }: UseChangeSessionLanguageOptions = {}) => {
    const { t, i18n } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения языка сессии"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: (_, variables) => {
            var language = variables.LanguageId === LanguageEnum.Russian ? "ru" : variables.LanguageId === LanguageEnum.English ? "en" : variables.LanguageId === LanguageEnum.Turkish ? "tr" : "ir";
            i18n.changeLanguage(language);
            showNotification({
                title: t("Успех!"),
                message: t("Язык сессии успешно изменен"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: changeSessionLanguage,
    });
};
