import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translationEN.json"
import translationRU from "./locales/ru/translationRU.json"
import translationTR from "./locales/tr/translationTR.json"
import translationIR from "./locales/ir/translationIR.json"
import storage from "./utils/storage";

const language = storage.getLanguage();

const resources = {
    en: {
        translation: translationEN,
    },
    ru: {
        translation: translationRU,
    },
    tr: {
        translation: translationTR,
    },
    ir: {
        translation: translationIR,
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "ru",
        lng: language || "ru",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
