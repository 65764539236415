import React from "react";
import { Flex } from "@mantine/core";
import { UserDto } from "src/features/auth";
import { useAuth } from "src/lib/auth";
import { IECForm } from "src/components/IEC/IECForm";

interface Props { }

const IECView: React.FC<Props> = () => {
    const { user } = useAuth();

    return (
        <Flex sx={{ flexGrow: 1, height: "100vh", width: "100%" }}>
            <div className="flex flex-1">< IECForm /> </div>
        </Flex>
    );
};

export default IECView;
