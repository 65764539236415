import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, string } from "yup";
import { Button, Group, Textarea, TextInput, useMantineTheme, Text, FileInput } from "@mantine/core";
import { CreateFirmwareCommand, useCreateFirmware } from "../api/createFirmware";
import { useForm, yupResolver } from "@mantine/form";
import { Dropzone } from '@mantine/dropzone'
import { IconFile, IconUpload, IconX } from "@tabler/icons-react";

interface CreateFirmwareDrawerProps {
    onComplete: () => void;
}

export const CreateFirmwareDrawer: React.FC<CreateFirmwareDrawerProps> = ({ onComplete }) => {
    const { t } = useTranslation();
    const createFirmwareMutation = useCreateFirmware();
    const [Content, setValue] = useState<File | null>(null);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const form = useForm({
        initialValues: {
            Name: "",
            Comment: "",
        },
    });
    const theme = useMantineTheme();

    function handleClose() {
        onComplete();
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                var formData = new FormData();
                formData.append("Name", values.Name);
                formData.append("Comment", values.Comment);
                if (Content != null) {
                    formData.append("Content", Content);
                }
                await createFirmwareMutation.mutateAsync({
                    formData: formData,
                });
                handleClose();
            })}
        >
            <div className="space-y-4 flex-auto">
                <TextInput
                    id="Name"
                    name="Name"
                    placeholder={t("Название")}
                    label={t("Название")}
                    {...form.getInputProps("Name")}
                />
                <Textarea
                    id="Comment"
                    name="Comment"
                    placeholder={t("Комментарий")}
                    label={t("Комментарий")}
                    {...form.getInputProps("Comment")}
                />
                <FileInput
                    id="Content"
                    name="Content"
                    placeholder={t("Нажмите для выбора файла")}
                    label={t("Файл прошивки")}
                    onChange={setValue}
                />
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {t("Создать")}
                </Button>
            </div>
        </form>
    );
};
