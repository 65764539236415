import download from "downloadjs";
import { http } from "src/lib/http";

export interface IECtableSettings {
    UserId: number;
}


export async function getIECtable(UserId: IECtableSettings, filename: string) {
    const { data } = await http.post("/iectables", UserId, {
        responseType: "blob",
    });
    download(data, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}
