import { axios } from "src/lib/axios";
import { LoginResponse } from "../types";

export type LoginCommand = {
    Login: string;
    Password: string;
};

export async function login(form: LoginCommand): Promise<LoginResponse> {
    return await axios.post("/users/login", form);
}
