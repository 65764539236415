import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Paper, Table, Tabs } from "@mantine/core";
import { useIECChannelTC } from "../api/getIECChannelTC";
import { UserDto } from "src/features/auth";
import { DataTable } from "mantine-datatable";

type TCTablesSettingsProps = {
    user: UserDto;
};

export const TCTableSettings: React.FC<TCTablesSettingsProps> = ({ user }) => {
    const { t } = useTranslation();
    const [activePage, setPage] = useState(1);
    const { data } = useIECChannelTC({
        request: {
            UserId: user.Id,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });
    return (
        <Tabs variant="outline" orientation="vertical" defaultValue="MDD">
            <Flex style={{ width: "100%" }}>
                <Paper className="mb-4" p="md" shadow="sm" style={{ marginRight: "1rem" }}>
                    <Tabs.List>
                        <Tabs.Tab value="MDD">{t("МДД")}</Tabs.Tab>
                        <Tabs.Tab value="ShortCircuit">{t("КЗ")}</Tabs.Tab>
                        <Tabs.Tab value="Universal">{t("Универсальное")}</Tabs.Tab>
                    </Tabs.List>
                </Paper>
                <Paper className="mb-4" p="md" shadow="sm" style={{ flex: 1 }}>
                    <Tabs.Panel value="MDD">
                        <DataTable
                            minHeight={150}
                            withBorder
                            borderRadius="sm"
                            withColumnBorders
                            striped
                            highlightOnHover
                            records={data?.Page}
                            columns={[
                                {
                                    accessor: "DeviceId",
                                    title: "Уставка",
                                    width: 100,
                                    render: (data) => { return data.Description }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Значение",
                                    render: (data) => { return data.Value }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Порт",
                                    textAlignment: "right",
                                    render: (data) => { return data.Port }
                                },
                            ]}
                        />
                    </Tabs.Panel>

                    <Tabs.Panel value="ShortCircuit">
                        <DataTable
                            minHeight={150}
                            withBorder
                            borderRadius="sm"
                            withColumnBorders
                            striped
                            highlightOnHover
                            records={data?.Page}
                            columns={[
                                {
                                    accessor: "DeviceId",
                                    title: "Уставка",
                                    width: 100,
                                    render: (data) => { return data.Description }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Значение",
                                    render: (data) => { return data.Value }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Порт",
                                    textAlignment: "right",
                                    render: (data) => { return data.Port }
                                },
                            ]}
                        />
                    </Tabs.Panel>

                    <Tabs.Panel value="Universal">
                        <DataTable
                            minHeight={150}
                            withBorder
                            borderRadius="sm"
                            withColumnBorders
                            striped
                            highlightOnHover
                            records={data?.Page}
                            columns={[
                                {
                                    accessor: "DeviceId",
                                    title: "Уставка",
                                    width: 100,
                                    render: (data) => { return data.Description }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Значение",
                                    render: (data) => { return data.Value }
                                },
                                {
                                    accessor: "SerialNumber",
                                    title: "Порт",
                                    textAlignment: "right",
                                    render: (data) => { return data.Port }
                                },
                            ]}
                        />
                    </Tabs.Panel>
                </Paper>
            </Flex>
        </Tabs>
    );
};

TCTableSettings.displayName = "TCSettings";
