import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { ChannelTIDto } from "../types";

export interface GetIECChannelTIQuery extends PagedRequest {
    UserId: number;
}

export const getIECChannelTI = (request: GetIECChannelTIQuery): Promise<PagedCollection<ChannelTIDto>> => {
    return axios.post(`/IEC/${request.UserId}/channelti`, request);
};

type QueryFnType = typeof getIECChannelTI;

type UseIECChannelTIOptions = {
    request: GetIECChannelTIQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useIECChannelTI = ({ request, config }: UseIECChannelTIOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["IEC/{UserId}/channelti", request],
        queryFn: () => getIECChannelTI(request),
    });
};
