import { xor } from "lodash";
import { UserDto } from "src/features/auth";
import create from "zustand";

export type UsersStore = {
    selectedId?: number;
    selectUser: (user: UserDto) => void;
    unselectUser: () => void;
};

export const useUsersStore = create<UsersStore>((set) => ({
    selectUser: (user: UserDto) => {
        set((state) => ({
            ...state,
            selectedId: user.Id,
        }));
    },
    unselectUser: () => {
        set((state) => ({
            ...state,
            selectedId: undefined,
        }));
    },
}));
