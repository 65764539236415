import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { ChannelThresholdDto } from "../types";

export const getChannelThresholds = (channelId: number): Promise<ChannelThresholdDto[]> => {
    return axios.get(`channels/${channelId}/thresholds`);
};

type QueryFnType = typeof getChannelThresholds;

type UseChannelThresholdsOptions = {
    channelId: number;
    config?: QueryConfig<QueryFnType>;
};

export const useChannelThresholds = ({ channelId, config }: UseChannelThresholdsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["channels/{channelId}/thresholds", channelId],
        queryFn: () => getChannelThresholds(channelId),
    });
};
