import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export const deleteFirmware = (id: number) => {
    return axios.delete(`/firmware/${id}`);
};

type UseDeleteFirmwareOptions = {
    config?: MutationConfig<typeof deleteFirmware>;
};

export const useDeleteFirmware = ({ config }: UseDeleteFirmwareOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при удалении прошивки"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("firmwares");
            showNotification({
                title: t("Успех!"),
                message: t("Прошивка удалена"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: deleteFirmware,
    });
};
