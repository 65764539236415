import { Badge, Box, Group, Paper } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeviceDto, DeviceModelEnum } from "src/features/devices/types";

type MarkerProps = {
    device: DeviceDto;
};

export const Marker: React.FC<MarkerProps> = ({ device }) => {
    const { t } = useTranslation();
    const deviceModels = {
        [DeviceModelEnum.RDM]: t("МДД"),
        [DeviceModelEnum.RDMShortCircuit]: t("КЗ"),
        [DeviceModelEnum.RDMUniversal]: t("Универсальное"),
        [DeviceModelEnum.RDMUniversal2]: t("Универсальное 2.1"),
        [DeviceModelEnum.RDMUniversal22]: t("Универсальное 2.2"),
    };

    return (
        <Group spacing="xs">
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.colors.dark,
                    height: 12,
                    width: 12,
                    color: "#fff",
                    opacity: 1,
                    zIndex: 2,
                    borderRadius: "50%",
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                })}
            ></Box>
            <Paper
                shadow="sm"
                px={4}
                // sx={{
                //     borderRadius: "50%",
                //     display: "flex",
                //     alignItems: "center",
                // }}
            >
                <Group>
                    <Badge radius="xs" variant="filled" color="dark">
                        {deviceModels[device.DeviceModel]}
                    </Badge>

                    <Box
                        sx={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            background: "hsla(0, 0%, 100%, 0.8)",
                            display: "inline-block",
                            verticalAlign: "middle",
                            zIndex: 1,
                            padding: "0 12px 0 17px",
                            marginLeft: "-14px",
                            borderRadius: "14px",
                            maxWidth: "200px",
                            color: "#303030",
                            height: "28px",
                            lineHeight: "28px",
                        }}
                    >
                        {device.Name}
                    </Box>
                </Group>
            </Paper>
        </Group>
    );
};

Marker.displayName = "Marker";
