import { Button, Select } from "@mantine/core";
import React from "react";
import { SchemaOf, object, number } from "yup";
import { BindDeviceCommand, useBindDevice } from "../api/bindDevice";
import { useUsers } from "../../users/api/getUsers";
import { useTranslation } from "react-i18next";
import { DeviceDto } from "../types";
import { useForm, yupResolver } from "@mantine/form";

const UpdateDeviceCommandSchema: SchemaOf<BindDeviceCommand> = object({
    UserId: number().required(),
    DeviceId: number().required(),
});

type BindDeviceDrawerProps = {
    device: DeviceDto;
    onComplete: () => void;
};

export const BindDeviceDrawer: React.FC<BindDeviceDrawerProps> = ({ device, onComplete }) => {
    const { t } = useTranslation();
    const bindDeviceMutation = useBindDevice();

    const form = useForm<BindDeviceCommand>({
        initialValues: {
            DeviceId: device.Id,
            UserId: null,
        },
        validate: yupResolver(UpdateDeviceCommandSchema),
    });

    const { data: users } = useUsers();

    function handleClose() {
        onComplete();
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await bindDeviceMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <div className="space-y-4 flex-auto">
                <Select
                    data={users?.map((x) => ({ label: x.UserName, value: x.Id })) || []}
                    label={t("Привязка к пользователю")}
                    searchable
                    nothingFound="No options"
                    {...form.getInputProps("UserId")}
                />
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена").toString()}
                </Button>
                <Button color="primary" type="submit">
                    {t("Привязать").toString()}
                </Button>
            </div>
        </form>
    );
};

BindDeviceDrawer.displayName = "BindDeviceDrawer";
