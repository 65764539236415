import { Flex } from "@mantine/core";
import React from "react";
import { useTelegramBindings } from "src/features/profile/api/getTelegramBindings";
import { useToggleChannelThresholdNotification } from "../api/toggleChannelThresholdNotification";
import { ChannelThresholdNotificationDto } from "../types";
import { ChannelThresholdNotificationCard } from "./ChannelThresholdNotificationCard";

type ChannelThresholdNotificationsProps = {
    channelId: number;
    thresholdId: number;
    thresholdNotifications: ChannelThresholdNotificationDto[];
};

export const ChannelThresholdNotifications: React.FC<ChannelThresholdNotificationsProps> = ({
    channelId,
    thresholdId,
    thresholdNotifications,
}) => {
    const { data: telegramBindings } = useTelegramBindings({ onlyConnected: true });
    const { mutateAsync: toggleAsync } = useToggleChannelThresholdNotification({ channelId });
    return (
        <Flex direction="column" gap="xs">
            {telegramBindings?.map((binding) => (
                <ChannelThresholdNotificationCard
                    checked={thresholdNotifications.some((x) => x.TelegramBinding?.Id === binding.Id && x.Enabled)}
                    onToggle={() => toggleAsync({ ChannelThresholdId: thresholdId, TelegramBindingId: binding.Id })}
                    telegramBinding={binding}
                />
            ))}
        </Flex>
    );
};

ChannelThresholdNotifications.displayName = "ChannelThresholdNotifications";
