import { Route, Routes } from "react-router-dom";

import { TelegramBinding } from "./TelegramBinding";
import { Profile } from "./Profile";
import { IEC } from "./IEC";
import { Language } from "./Language";

export const ProfileRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<Profile />}>
                <Route path="telegram" element={<TelegramBinding />}></Route>
                <Route path="iec" element={<IEC />}></Route>
                <Route path="language" element={<Language />}></Route>
            </Route>
            <Route path="*" element={<Profile />} />
        </Routes>
    );
};
