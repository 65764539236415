import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Group, Select } from "@mantine/core";
import { useCurrentUser } from "../../auth";
import { useChangeLanguage } from "src/features/profile/api/changeLanguage";
import { useChangeSessionLanguage } from "src/features/profile/api/changeSessionLanguage";
import { LanguagePicker } from "src/features/language/LanguagePicker";
import storage from "src/utils/storage";

type ChangeLanguageDrawerProps = {};

export const ChangeLanguageDrawer: React.FC<ChangeLanguageDrawerProps> = () => {
    const { t } = useTranslation();
    const { data: user } = useCurrentUser();
    const setLanguageMutation = useChangeLanguage();
    const setSessionLanguageMutation = useChangeSessionLanguage();

    const [sessionLanguage, setSessionLanguage] = useState<number>(Number(storage.getLanguage() === "ru" ? 1 : storage.getLanguage() === "en" ? 2 : storage.getLanguage() === "tr" ? 3 : storage.getLanguage() === "ir" ? 4 : user?.Language));
    const [language, setLanguage] = useState<number>(Number(storage.getLanguage() === "ru" ? 1 : storage.getLanguage() === "en" ? 2 : storage.getLanguage() === "tr" ? 3 : storage.getLanguage() === "ir" ? 4 : user?.Language));

    useEffect(() => {
        setSessionLanguage(storage.getLanguage() === "ru" ? 1 : storage.getLanguage() === "en" ? 2 : storage.getLanguage() === "tr" ? 3 : storage.getLanguage() === "ir" ? 4 : user?.Language ? Number(user.Language) : 1);
    }, [user]);

    useEffect(() => {
        setLanguage(storage.getLanguage() === "ru" ? 1 : storage.getLanguage() === "en" ? 2 : storage.getLanguage() === "tr" ? 3 : storage.getLanguage() === "ir" ? 4 : user?.Language ? Number(user.Language) : 1);
    }, [user]);

    if (!user) {
        return null;
    }

    function handleSessionLanguageChange() {
        setSessionLanguageMutation.mutateAsync({
            LanguageId: Number(sessionLanguage),
        });
    }

    function handleLanguageChange() {
        setLanguageMutation.mutateAsync({
            UserId: user!.Id,
            LanguageId: Number(language),
        });
    }

    return (
        <div>
            <div className="space-y-4 flex-auto">
                {t("Язык сессии")}
                <Group>
                    <LanguagePicker onSelectLanguage={setSessionLanguage} language={sessionLanguage} />
                    <Button onClick={handleSessionLanguageChange}>{t("Применить")}</Button>
                </Group>
            </div>

            <div className="space-y-4 flex-auto">
                {t("Язык по умолчанию")}
                <Group>
                    <LanguagePicker onSelectLanguage={setLanguage} language={language} />
                    <Button onClick={handleLanguageChange}>{t("Применить")}</Button>
                </Group>
            </div>
        </div>
    );
};
