import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export interface SaveIceV2ConstantComponentCommand {
    DeviceId: number;
    IceV2ConstantComponentWindow?: number;
    IceV2ConstantComponentWindowStartDate?: Date | string;
    IceV2ConstantComponent?: number;
    IceV2ConstantComponentIgnoreIceTemperature?: boolean;
}
export const saveIceV2ConstantComponent = (data: SaveIceV2ConstantComponentCommand) => {
    return axios.post(`/devices/${data.DeviceId}/ice-v2-constant-component`, data);
};

type UseSaveIceV2ConstantComponentOptions = {
    config?: MutationConfig<typeof saveIceV2ConstantComponent>;
};

export const useSaveIceV2ConstantComponent = ({ config }: UseSaveIceV2ConstantComponentOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка сохранения параметров расчета постоянной составляющей!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("");
            showNotification({
                title: t("Успех!"),
                message: t("Параметры расчета постоянной составляющей сохранены!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: saveIceV2ConstantComponent,
    });
};
