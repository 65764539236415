import { isNaN, some } from "lodash";
import { getServerUrls } from "../api/getServerUrls";
import { DeviceParameterDto, ParameterValidationStrategyEnum } from "../types";

type ParameterValueValidateProps = {
    deviceParameter: DeviceParameterDto;
};

type ParameterValueValidationResult = string | null;

export function useParameterValueValidate({ deviceParameter }: ParameterValueValidateProps) {
    async function noop(value: string): Promise<ParameterValueValidationResult> {
        return null;
    }

    async function numberValidation(value: string): Promise<ParameterValueValidationResult> {
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
            return "Значение должно быть числом!";
        }

        if (numberValue < deviceParameter.MinValue! || numberValue > deviceParameter.MaxValue!) {
            return `Значение должно быть в диапазоне ${deviceParameter.MinValue!}-${deviceParameter.MaxValue!}!`;
        }

        return null;
    }

    async function serverUrlValidation(value: string): Promise<ParameterValueValidationResult> {
        const serverUrls = await getServerUrls();

        if (!some(serverUrls, (url) => url.Url === value)) {
            return "Адрес должен быть выбран из списка допустимых URL!";
        }

        return null;
    }

    let validateAsync = noop;
    switch (deviceParameter.ValidationStrategy) {
        case ParameterValidationStrategyEnum.NumberValidationStrategy:
            validateAsync = numberValidation;
            break;
        case ParameterValidationStrategyEnum.ServerUrlValidationStrategy:
            validateAsync = serverUrlValidation;
            break;
        default:
            break;
    }

    return {
        validateAsync,
    };
}
