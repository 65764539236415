import download from "downloadjs";
import { http } from "src/lib/http";

export interface IChannelsReportSettings {
    ReportSettings: IDeviceReportSettings[];
    StartDt: Date;
    EndDt: Date;
}

export interface IDeviceReportSettings {
    Id: number;
    ChannelIds: number[];
}

export async function getReport(channelReportSettings: IChannelsReportSettings, filename: string) {
    const { data } = await http.post("/reports", channelReportSettings, {
        responseType: "blob",
    });
    download(data, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}
