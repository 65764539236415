import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { IECUserDeviceDto } from "../types";

export interface GetIECUserDevicesQuery extends PagedRequest {
    UserId: number;
}

export const getIECUserDevices = (request: GetIECUserDevicesQuery): Promise<PagedCollection<IECUserDeviceDto>> => {
    return axios.post(`/IEC/${request.UserId}/objects`, request);
};

type QueryFnType = typeof getIECUserDevices;

type UseIECUserDevicesOptions = {
    request: GetIECUserDevicesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useIECUserDevices = ({ request, config }: UseIECUserDevicesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["IEC/{UserId}/objects", request],
        queryFn: () => getIECUserDevices(request),
    });
};
