import Container from "src/components/auth/Container";
import { useNavigate } from "react-router-dom";

import { RegisterForm } from "../components/RegisterForm";

export const Register = () => {
    const navigate = useNavigate();

    return (
        <Container className="background-pattern">
            <RegisterForm
                onSuccess={() => {
                    navigate("/");
                }}
            />
        </Container>
    );
};
