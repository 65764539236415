import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { ParameterDto } from "../types";

export const getParameters = (deviceModelId: number): Promise<ParameterDto[]> => {
    return axios.get(`/parameters/?deviceModelId=${deviceModelId}`);
};

type QueryFnType = typeof getParameters;

type UseParametersOptions = {
    deviceModelId: number;
    config?: QueryConfig<QueryFnType>;
};

export const useParameters = ({ deviceModelId, config }: UseParametersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["parameters", deviceModelId],
        queryFn: () => getParameters(deviceModelId),
    });
};
