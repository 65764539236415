import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ChartData } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type ChartDataQuery = {
    deviceId: number;
    averaging?: number | null;
    startDt?: Date | string;
    endDt?: Date | string;
};

export const getChartData = (data: ChartDataQuery): Promise<ChartData> => {
    return axios.get(`/devices.getChartData/${data.deviceId}`, {
        params: {
            startDt: data.startDt,
            endDt: data.endDt,
            averaging: data.averaging,
        },
    });
};

type QueryFnType = typeof getChartData;

type UseChartDataOptions = {
    config?: QueryConfig<QueryFnType>;
    data: ChartDataQuery;
};

export const useChartData = ({ config, data }: UseChartDataOptions) => {
    const { t } = useTranslation();

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["chart-data", data],
        queryFn: () => getChartData(data),
        refetchInterval: 30000,
        staleTime: 30000,
        onError: () => {
            showNotification({
                message: t("Ошибка загрузки данных графиков"),
                title: t("Серверная ошибка"),
            });
        },
        ...config,
    });
};
