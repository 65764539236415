import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { ServerUrlDto } from "../types";

export const getServerUrls = (): Promise<ServerUrlDto[]> => {
    return axios.get("/server-urls");
};

type QueryFnType = typeof getServerUrls;

type UseServerUrlsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useServerUrls = ({ config }: UseServerUrlsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["/server-urls"],
        queryFn: getServerUrls,
        ...config,
    });
};
