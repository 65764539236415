import { ActionIcon, Box, Checkbox, Flex, Modal, Title, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSettings } from "@tabler/icons-react";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Notifications } from "src/features/notifications";
import { DeviceChannelDto } from "../types";

type ChannelsTableRowProps = {
    onChannelClick: (channel: DeviceChannelDto) => void;
    channel: DeviceChannelDto;
    checked: boolean;
};

export const ChannelsTableRow: React.FC<ChannelsTableRowProps> = ({ onChannelClick, channel, checked }) => {
    const { t } = useTranslation();
    const [openNotificationSettings, notificationSettingsHandlers] = useDisclosure(false);

    const handleNotificationSettings = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        notificationSettingsHandlers.open();
    };

    return (
        <>
            <tr
                className="h-14"
                onClick={() => {
                    onChannelClick(channel);
                }}
                key={channel.Id}
            >
                <td>
                    <Flex justify="center">
                        <Checkbox color="primary" onChange={(event) => { }} checked={checked} />
                    </Flex>
                </td>
                <td>{t(channel.Name)}</td>
                <td>
                    <Tooltip
                        disabled={isEmpty(channel.CurrentChannelThreshold?.MessageTemplate)}
                        label={channel.CurrentChannelThreshold?.MessageTemplate}
                    >
                        <Flex justify="center" gap="xs">
                            <Flex
                                justify="flex-end"
                                px="xs"
                                bg={channel.CurrentChannelThreshold?.Color}
                                style={{"padding": 0}}
                                sx={(theme) => ({
                                    flexGrow: 0,
                                    borderRadius: theme.radius.md,
                                    color: channel.CurrentChannelThreshold ? "white" : "black",
                                    fontWeight: "bold",
                                })}
                            >
                                {!isNil(channel.Value) ? <>{channel.Value?.toFixed(2)}</> : "-"}
                            </Flex>
                            <Flex
                                justify="flex-start"
                                sx={(theme) => ({
                                    flexBasis: "fit-content",
                                    flexShrink: 0,
                                })}
                            >
                                {channel.Unit && ` ${t(channel.Unit)}`}
                            </Flex>
                        </Flex>
                    </Tooltip>
                </td>
                <td align="center">
                    {channel.Timestamp ? dayjs(channel.Timestamp).format("DD.MM.YYYY HH:mm:ss") : "-"}
                </td>
                <td align="center">
                    <ActionIcon onClick={handleNotificationSettings} variant="default">
                        <IconSettings />
                    </ActionIcon>
                </td>
            </tr>
            <Modal
                title={<Title order={3}>{t("Настройки канала")}</Title>}
                opened={openNotificationSettings}
                onClose={notificationSettingsHandlers.close}
                size="xl"
            >
                <Notifications channelUnit={channel.Unit} channelId={channel.Id} />
            </Modal>
        </>
    );
};

ChannelsTableRow.displayName = "ChannelsTableRow";
