import download from "downloadjs";
import { http } from "src/lib/http";

export interface IUndefinedDeviceSettings {
    StartDt: Date;
    EndDt: Date;
}

export async function getUndefinedDevicesFile(undefinedDeviceSettings: IUndefinedDeviceSettings, filename: string) {
    const { data } = await http.post("/undefineddevices", undefinedDeviceSettings, {
        responseType: "blob",
    });
    download(data, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}
