import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { NotificationProps } from "@mantine/notifications";
import { useEffect } from "react";
import { removeNotification } from "src/store/models/notifications";
import { showNotification } from "@mantine/notifications";

let displayed: string[] = [];

export function useNotifier() {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector((store) => store.notifications.notifications || []);

    const storeDisplayed = (id: string) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ id, message, type, title }) => {
            // if (dismissed) {
            //     // dismiss snackbar using notistack
            //     closeSnackbar(key);
            //     return;
            // }

            // do nothing if snackbar is already displayed
            if (displayed.includes(id)) {
                return;
            }

            showNotification({
                id: id,
                title: title,
                message: message,
                autoClose: false,
                color:
                    type === "info"
                        ? "blue"
                        : type === "error"
                        ? "red"
                        : type === "success"
                        ? "green"
                        : type === "warning"
                        ? "orange"
                        : "blue",
                onClose(props: NotificationProps) {
                    if (props.id) {
                        dispatch(removeNotification(props.id));
                        removeDisplayed(id);
                    }
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(id);
        });
    }, [notifications, dispatch]);
}
