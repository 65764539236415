import {
    ActionIcon,
    Card,
    Flex,
    Tooltip,
    Text,
    Badge,
    Modal,
    Image,
    CopyButton,
    Menu,
    Anchor,
    Title,
    Code,
    Divider,
} from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import { IconCheck, IconCopy, IconDotsVertical, IconUnlink, IconUser, IconUsers } from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteTelegramBinding } from "../api/deleteTelegramBinding";
import { TelegramBindingDto } from "../types";

type TelegramBindingCardProps = {
    data: TelegramBindingDto;
};

export const TelegramBindingCard: React.FC<TelegramBindingCardProps> = ({ data }) => {
    const { t } = useTranslation();
    const { hovered, ref } = useHover();
    const { mutateAsync } = useDeleteTelegramBinding();
    const [opened, { close, open }] = useDisclosure(false);
    const [openedGroup, { close: closeGroup, open: openGroup }] = useDisclosure(false);

    const handleOpenLink = () => {
        window.open(data.Url?.Url, "_blank")?.focus();
    };

    const handleDelete = async () => {
        await mutateAsync({ Id: data.Id });
    };

    const url = data.Url?.Url;
    const groupCommand = `/${url?.slice(url?.indexOf("start"))?.replace("=", "")}`;

    return (
        <>
            <Card
                withBorder
                radius="md"
                px="lg"
                py="xs"
                shadow={hovered ? "sm" : "0 1px 2px 0 rgb(0 0 0 / 0.05)"}
                ref={ref}
            >
                <Flex justify="space-between" align="center">
                    <Flex direction="column" sx={{ flexBasis: "30%", flexGrow: 1 }}>
                        <Text weight="bold">{data.Name}</Text>
                        <Flex gap="xs">
                            <Text color="dimmed">{t("Дата подключения")}:</Text>
                            <Text color="dimmed">
                                {data.ConnectDate ? dayjs(data.ConnectDate).format("DD.MM.YY HH:mm") : "-"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex sx={{ flexBasis: "100px", flexShrink: 0, flexGrow: 1 }}>
                        <Badge color={data.ConnectDate ? "green" : "gray"} size="sm">
                            {data.ConnectDate ? t("Привязан") : t("Не привязан")}
                        </Badge>
                    </Flex>
                    <Flex gap="sm" sx={{ flexBasis: "100px", flexShrink: 0 }} justify="flex-end">
                        <Menu withinPortal shadow="md" width={280}>
                            <Menu.Target>
                                <ActionIcon variant="transparent" color="dark">
                                    <IconDotsVertical />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                {!data.ConnectDate && (
                                    <>
                                        <Menu.Item onClick={open} icon={<IconUser size={14} />}>
                                            {t("Привязать аккаунт")}
                                        </Menu.Item>
                                        <Menu.Item onClick={openGroup} icon={<IconUsers size={14} />}>
                                            {t("Привязать группу")}
                                        </Menu.Item>
                                        <Menu.Divider />
                                    </>
                                )}

                                <Menu.Item onClick={handleDelete} color="red" icon={<IconUnlink size={14} />}>
                                    {t("Удалить привязку Telegram")}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                </Flex>
            </Card>
            <Modal
                size="xl"
                centered
                opened={opened}
                onClose={close}
                title={<Title order={3}>{t("Привязать аккаунт")}</Title>}
            >
                <Text>
                    {t(
                        `Для привязки перейдите по ссылке ниже или скопируйте ее и откройте в браузере или отсканируйте QR-код`
                    )}
                </Text>
                <Flex mt="md" align="center" gap="md">
                    <Anchor weight={500} href={url!} target="_blank">
                        {url}
                    </Anchor>
                    <CopyButton value={url!} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? t("Скопировано") : t("Скопировать")} withArrow position="right">
                                <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </Flex>
                <Divider my="md" />
                <Flex align="center" justify="center">
                    <Image width={250} height={250} src={`data:image/png;base64, ${data.Url?.QRCode}`} />
                </Flex>
            </Modal>
            <Modal
                size="lg"
                centered
                opened={openedGroup}
                onClose={closeGroup}
                title={<Title order={3}>{t("Привязать группу")}</Title>}
            >
                <Text>{t(`Для привязки скопируйте команду ниже и отправьте ее в группу к которой добавлен бот`)}</Text>
                <Flex mt="md" align="center" gap="md">
                    <Code color="gray">
                        <Text weight={500} size="sm">
                            {groupCommand}
                        </Text>
                    </Code>
                    <CopyButton value={groupCommand!} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? t("Скопировано") : t("Скопировать")} withArrow position="right">
                                <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </Flex>
            </Modal>
        </>
    );
};

TelegramBindingCard.displayName = "TelegramBindingCard";
