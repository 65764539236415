import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { DeviceDto } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export const getDevices = (): Promise<DeviceDto[]> => {
    return axios.get(`/devices`);
};

type QueryFnType = typeof getDevices;

type UseShortCircuitsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useDevices = ({ config }: UseShortCircuitsOptions = {}) => {
    const { t } = useTranslation();
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["devices"],
        queryFn: () => getDevices(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке устройств"),
                title: t("Серверная ошибка"),
            });
        },
        ...config,
    });
};
