import { useQuery } from "react-query";
import { DimensionParametersDto } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type GetDimensionParametersQuery = {
    deviceId: number;
};

export const getDimensionParameters = (query: GetDimensionParametersQuery): Promise<DimensionParametersDto> => {
    return axios.get(`/devices/${query.deviceId}/dimension-parameters`);
};

type QueryFnType = typeof getDimensionParameters;

type UseDimensionParametersOptions = {
    config?: QueryConfig<QueryFnType>;
    query: GetDimensionParametersQuery;
};

export const useDimensionParameters = ({ query, config }: UseDimensionParametersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["dimension-parameters", query.deviceId],
        queryFn: () => getDimensionParameters(query),
        ...config,
    });
};
