import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { OscilloscopeDto } from "../types";

export interface GetOscilloscopesQuery {
    DeviceId: number;
}

export const getOscilloscopes = (request: GetOscilloscopesQuery): Promise<OscilloscopeDto[]> => {
    return axios.post(`devices/${request.DeviceId}/oscilloscopes`, request);
};

type QueryFnType = typeof getOscilloscopes;

type UseOscilloscopesOptions = {
    request: GetOscilloscopesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useOscilloscopes = ({ request, config }: UseOscilloscopesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["devices/{DeviceId}/oscilloscopes", request],
        queryFn: () => getOscilloscopes(request),
    });
};
