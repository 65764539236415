import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export interface SaveIceV2ParametersCommand {
    DeviceId: number;
    IceV2ZeroGDrift?: number;
    IceV2Temperature0?: number;
    IceV2LengthReal?: number;
    IceV2LengthReduced?: number;
    IceV2ElasticModulus?: number;
    IceV2CrossSectionalArea?: number;
    IceV2Weight?: number;
    IceV2Diameter?: number;
    IceV2Tension?: number;
    IceV2ThermalExpansionCoefficient?: number;
    InstallationDate?: Date | string;
    IceV2ControlStartTemperature?: number;
    IceV2ControlStopTemperature?: number;
    IceV2ControlStopIcePerMeter?: number;
    IceV2MinimumIceTemperature?: number;
    IceV2MovingAverageWindow?: number;
}

export const saveIceV2Parameters = (data: SaveIceV2ParametersCommand) => {
    return axios.post(`/devices/${data.DeviceId}/ice-v2-parameters`, data);
};

type UseSaveIceV2ParametersOptions = {
    config?: MutationConfig<typeof saveIceV2Parameters>;
};

export const useSaveIceV2Parameters = ({ config }: UseSaveIceV2ParametersOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка сохранения параметров!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["ice-v2-parameters"] });
            showNotification({
                title: t("Успех!"),
                message: t("Параметры успешно сохранены!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: saveIceV2Parameters,
    });
};
