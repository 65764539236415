import { Button, Select } from "@mantine/core";
import React from "react";
import { SchemaOf, object, number } from "yup";
import { FirmwareDeviceCommand, useFirmwareDevice } from "../api/firmwareDevice";
import { useFirmwares } from "../api/getFirmwares";
import { useTranslation } from "react-i18next";
import { FirmwareDto } from "../types/index";
import { useForm, yupResolver } from "@mantine/form";
import { DeviceDto } from "src/features/devices/types";

const FirmwareDeviceCommandSchema: SchemaOf<FirmwareDeviceCommand> = object({
    FirmwareId: number().required(),
    DeviceId: number().required(),
});

type FirmwareDeviceDrawerProps = {
    device: DeviceDto;
    onComplete: () => void;
};

export const FirmwareDeviceDrawer: React.FC<FirmwareDeviceDrawerProps> = ({ device, onComplete }) => {
    const { t } = useTranslation();
    const firmwareDeviceMutation = useFirmwareDevice();

    const form = useForm<FirmwareDeviceCommand>({
        initialValues: {
            DeviceId: device.Id,
            FirmwareId: null,
        },
        validate: yupResolver(FirmwareDeviceCommandSchema),
    });

    const { data: firmwares } = useFirmwares();

    function handleClose() {
        onComplete();
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await firmwareDeviceMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <div className="space-y-4 flex-auto">
                <Select
                    data={firmwares?.map((x) => ({ label: x.Name, value: x.Id })) || []}
                    label={t("Перепрошивка устройства")}
                    searchable
                    nothingFound="No options"
                    {...form.getInputProps("FirmwareId")}
                />
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена").toString()}
                </Button>
                <Button color="primary" type="submit">
                    {t("Перепрошить").toString()}
                </Button>
            </div>
        </form>
    );
};

FirmwareDeviceDrawer.displayName = "BindDeviceDrawer";
