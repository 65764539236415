import { useState } from 'react';
import { UnstyledButton, Menu, Image, Group } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import ru from './images/ru.png';
import en from './images/en.png';
import tr from './images/tr.png';
import ir from './images/ir.png';
import { useTranslation } from 'react-i18next';

//https://hatscripts.github.io/circle-flags/gallery

const data = [
    { label: 'Русский', value: 1, image: ru },
    { label: 'Английский', value: 2, image: en },
    { label: 'Турецкий', value: 3, image: tr },
    { label: 'Персидский', value: 4, image: ir },
];

interface LanguagePickerProps {
    onSelectLanguage: (language: number) => void;
    language: number;
}

export function LanguagePicker({ onSelectLanguage, language }: LanguagePickerProps) {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(data.find((item) => item.value === language) || data[0]);

    const items = data.map((item) => (
        <Menu.Item
            onClick={() => {
                setSelected(item);
                onSelectLanguage(item.value);
            }}
            key={item.label}
        >
            <Group>
                <Image src={item.image} width={18} height={18} />
                <span>{t(item.label)}</span>
            </Group>
        </Menu.Item>
    ));

    return (
        <Menu
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            radius="md"
            withinPortal
            width='200px'
        >
            <Menu.Target>
                <UnstyledButton sx={(theme) => ({
                    display: "flex",
                    width: "200px",
                    padding: "10px",
                    borderRadius: theme.radius.sm,
                    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.dark[6],
                    "& .icon": {
                        transition: "transform 0.3s",
                        transform: `rotate(${opened ? "180deg" : "0deg"})`,
                    }
                })}>
                    <Group>
                        <Image src={selected.image} width={22} height={22} />
                        <span>{t(selected.label)}</span>
                    </Group>
                    <IconChevronDown className='icon' size="1rem" stroke={1.5} />
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{items}</Menu.Dropdown>
        </Menu>
    );
}
