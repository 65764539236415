import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, number } from "yup";
import { Button, LoadingOverlay, Select } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { CreateDeviceParameterCommand, useCreateDeviceParameter } from "../api/createDeviceParameter";
import { useParameters } from "../api/getParameters";

const CreateDeviceParameterCommandSchema: SchemaOf<CreateDeviceParameterCommand> = object({
    ParameterId: number().required("Идентификатор параметра должен быть задан"),
    DeviceId: number().required("Идентификатор устройства должен быть задан"),
});

interface CreateDeviceParameterFormProps {
    onClose: () => void;
    deviceId: number;
}

export const CreateDeviceParameterForm: React.FC<CreateDeviceParameterFormProps> = ({ onClose, deviceId }) => {
    const { t } = useTranslation();
    const mutation = useCreateDeviceParameter();
    const form = useForm<CreateDeviceParameterCommand>({
        initialValues: {
            DeviceId: deviceId,
            ParameterId: 0,
        },
        validate: yupResolver(CreateDeviceParameterCommandSchema),
    });

    const [deviceModelId, setDeviceModelId] = useState(1);
    const { data: parameters, isLoading } = useParameters({
        deviceModelId,
    });

    function handleClose() {
        onClose();
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await mutation.mutateAsync(values);
                handleClose();
            })}
        >
            <LoadingOverlay visible={isLoading} />
            <div className="space-y-4 flex-auto">
                <Select
                    value={deviceModelId.toString()}
                    onChange={(value) => {
                        setDeviceModelId(Number(value));
                    }}
                    label={t("Тип устройства")}
                    data={[
                        { value: "1", label: t("МДД") },
                        { value: "2", label: t("КЗ") },
                        { value: "3", label: t("Универсальное") },
                        { value: "4", label: t("Универсальное 2.1") },
                        { value: "5", label: t("Универсальное 2.2") },
                    ]}
                />
                <Select
                    value={form.values.ParameterId.toString()}
                    onChange={(value) => {
                        form.setFieldValue("ParameterId", Number(value));
                    }}
                    label={t("Параметр")}
                    data={
                        parameters?.map((x) => ({
                            value: x.Id.toString(),
                            label: t(x.Name),
                        })) || []
                    }
                />
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {t("Создать")}
                </Button>
            </div>
        </form>
    );
};
