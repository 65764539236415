import { Card, Flex, Switch, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconBell, IconBellOff } from "@tabler/icons-react";
import React from "react";
import { TelegramBindingDto } from "../../profile";

type ChannelThresholdNotificationCardProps = {
    telegramBinding: TelegramBindingDto;
    checked: boolean;
    onToggle: (value: boolean) => void;
};

export const ChannelThresholdNotificationCard: React.FC<ChannelThresholdNotificationCardProps> = ({
    telegramBinding,
    onToggle,
    checked,
}) => {
    const { hovered, ref } = useHover();
    return (
        <Card shadow={hovered ? "sm" : "0 1px 2px 0 rgb(0 0 0 / 0.05)"} ref={ref} withBorder>
            <Flex justify="space-between" align="center">
                <Text weight="bold">{telegramBinding.Name}</Text>
                <Switch
                    size="md"
                    display="flex"
                    onLabel={<IconBellOff size="1rem" stroke={2.5} />}
                    offLabel={<IconBell size="1rem" stroke={2.5} />}
                    checked={checked}
                    onChange={(e) => onToggle(e.currentTarget.checked)}
                ></Switch>
            </Flex>
        </Card>
    );
};

ChannelThresholdNotificationCard.displayName = "ChannelThresholdNotificationCard";
