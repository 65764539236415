import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type DeleteChannelThresholdCommand = {
    ChannelId: number;
    Id: number;
};

export const deleteChannelThreshold = (data: DeleteChannelThresholdCommand) => {
    return axios.delete(`channels/${data.ChannelId}/thresholds/${data.Id}`);
};

type UseDeleteChannelThresholdOptions = {
    config?: MutationConfig<typeof deleteChannelThreshold>;
};

export const useDeleteChannelThreshold = ({ config }: UseDeleteChannelThresholdOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t(""),
                color: "red",
            });
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(["channels/{channelId}/thresholds", variables.ChannelId]);
            showNotification({
                title: t("Успех!"),
                message: t(""),
                color: "teal",
            });
        },
        ...config,
        mutationFn: deleteChannelThreshold,
    });
};
