import { Stack, NumberInput, Switch, Button, Paper } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React from "react";
import {
    SaveBreakDetectionParametersCommand,
    useSaveBreakDetectionParameters,
} from "../api/saveBreakDetectionParameters";
import { useTranslation } from "react-i18next";
import { BreakDetectionParametersDto } from "../types";
import { SchemaOf, object, number } from "yup";
import { boolean } from "yup";

const SaveBreakDetectionParametersCommandSchema: SchemaOf<SaveBreakDetectionParametersCommand> = object({
    DeviceId: number().required(),
    BreakDetectionEnabled: boolean().required(),
    BreakDetectionAngleDeviation: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    BreakDetectionMovingAverageWindow: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
});

type BreakDetectionProps = {
    deviceId: number;
    breakDetectionParameters: BreakDetectionParametersDto;
};

export const BreakDetection: React.FC<BreakDetectionProps> = ({ deviceId, breakDetectionParameters }) => {
    const { t } = useTranslation();
    const saveMutation = useSaveBreakDetectionParameters();
    const form = useForm<SaveBreakDetectionParametersCommand>({
        initialValues: {
            DeviceId: deviceId,
            ...breakDetectionParameters,
        },
        validate: yupResolver(SaveBreakDetectionParametersCommandSchema),
    });

    function handleClose() {
        form.reset();
    }

    return (
        <Paper className="mb-4" p="md" shadow="sm">
            <form
                onSubmit={form.onSubmit(async (values) => {
                    await saveMutation.mutateAsync(values);
                })}
            >
                <div className="flex-1 overflow-y-scroll">
                    <Stack>
                        <Switch
                            label={t("Обнаружение обрыва")}
                            {...form.getInputProps("BreakDetectionEnabled", { type: "checkbox" })}
                        />
                        <NumberInput
                            disabled={!form.values.BreakDetectionEnabled}
                            required={false}
                            label={t("Размер окна для расчета обрыва")}
                            step={1}
                            {...form.getInputProps("BreakDetectionMovingAverageWindow")}
                        />
                        <NumberInput
                            disabled={!form.values.BreakDetectionEnabled}
                            required={false}
                            label={t("Отклонение угла")}
                            step={1}
                            precision={8}
                            {...form.getInputProps("BreakDetectionAngleDeviation")}
                        />
                    </Stack>
                    <div className="space-x-3 mt-8 flex justify-end">
                        <Button onClick={handleClose}>{t("Отмена")}</Button>
                        <Button type="submit">{t("Сохранить")}</Button>
                    </div>
                </div>
            </form>
        </Paper>
    );
};

BreakDetection.displayName = "BreakDetection";
