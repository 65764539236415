import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { useMantineTheme } from "@mantine/core";
import { ChartChannelData } from "src/features/devices/types";

interface ChartProps {
    isMobile?: boolean;
    chartChannelData: ChartChannelData;
    width?: number;
    height?: number;
}

const MobileChartWrapper: React.FC<ChartProps> = ({ chartChannelData, width, height, isMobile }) => {
    const theme = useMantineTheme();
    const [chartOptions, setChartOptions] = useState<any>({
        chart: {
            id: chartChannelData.Id,
            type: "line",
            group: "energy",
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            followCursor: false,
            theme: "dark",
            x: {
                show: false,
            },
            marker: {
                show: false,
            },
            y: {
                title: {
                    formatter: function () {
                        return "";
                    },
                },
            },
        },
        colors: [theme.primaryColor],
        stroke: {
            width: [2],
        },
        xaxis: {
            type: "datetime",
            axisBorder: {
                show: false,
            },
            labels: {
                rotate: 0,
                formatter: function (value: string) {
                    return dayjs(value).format("DD.MM.YY HH:mm");
                },
                style: {
                    colors: [theme.fn.fontStyles().color],
                    fontSize: "12px",
                    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: [
            {
                tickAmount: 2,
                labels: {
                    minWidth: 50,
                    style: {
                        colors: [theme.fn.fontStyles().color],
                        fontSize: "12px",
                        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                    formatter: function (value: string) {
                        const numberValue = Number(value);
                        return chartChannelData.Unit
                            ? `${Number.isInteger(numberValue) ? value : numberValue.toFixed(2)} ${
                                  chartChannelData.Unit
                              }`
                            : value;
                    },
                },
                title: {
                    show: false,
                },
            },
        ],
    });
    const [chartSeries, setChartSeries] = useState<any>([
        {
            name: chartChannelData.Name,
            data: chartChannelData.Values.map((y, j) => ({
                x: chartChannelData.Timestamps[j],
                y: y.toFixed(2),
            })),
        },
    ]);

    useEffect(() => {
        function drawChart() {
            if (chartChannelData) {
                if (!chartOptions.xaxis) {
                    setChartOptions({
                        chart: {
                            id: chartChannelData.Id,
                            type: "line",
                            group: "energy",
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            followCursor: false,
                            theme: "dark",
                            x: {
                                show: false,
                            },
                            marker: {
                                show: false,
                            },
                            y: {
                                title: {
                                    formatter: function () {
                                        return "";
                                    },
                                },
                            },
                        },
                        colors: ["#1192e8"],
                        stroke: {
                            width: [2],
                        },
                        xaxis: {
                            type: "datetime",
                            labels: {
                                rotate: 0,
                                formatter: function (value: string) {
                                    return dayjs(value).format("DD.MM.YY HH:mm");
                                },
                            },
                        },
                        yaxis: [
                            {
                                tickAmount: 2,
                                axisTicks: {
                                    show: true,
                                },
                                axisBorder: {
                                    show: true,
                                    color: "#1192e8",
                                },
                                labels: {
                                    minWidth: 40,
                                    style: {
                                        colors: "#1192e8",
                                    },
                                    formatter: function (value: string) {
                                        const numberValue = Number(value);
                                        return chartChannelData.Unit
                                            ? `${Number.isInteger(numberValue) ? value : numberValue.toFixed(2)} ${
                                                  chartChannelData.Unit
                                              }`
                                            : value;
                                    },
                                },
                                title: {
                                    text: chartChannelData.Name,
                                    style: {
                                        color: "#1192e8",
                                    },
                                },
                            },
                        ],
                    });
                }

                setChartSeries([
                    {
                        name: chartChannelData.Name,
                        data: chartChannelData.Values.map((y, j) => ({
                            x: chartChannelData.Timestamps[j],
                            y: y.toFixed(2),
                        })),
                    },
                ]);
            }
        }

        drawChart();
    }, [chartChannelData, isMobile]);

    return (
        <Chart
            options={chartOptions}
            series={chartSeries}
            height={150}
            width={window.innerWidth - 24}
            type="line"
        ></Chart>
    );
};

export default MobileChartWrapper;
