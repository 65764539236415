import { Button, Card, Flex, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useChannelThresholds } from "../api/getChannelThresholds";
import { ChannelThreshold } from "./ChannelThreshold";
import { ChannelThresholdForm } from "./ChannelThresholdForm";

type NotificationsProps = {
    channelId: number;
    channelUnit?: string;
};

export const Notifications: React.FC<NotificationsProps> = ({ channelId, channelUnit }) => {
    const { t } = useTranslation();
    const { data } = useChannelThresholds({ channelId });

    const [createNewThreshold, setCreateNewThreshold] = useState<boolean>(false);

    return (
        <Flex direction="column" gap="lg">
            <Title weight="400" order={4}>
                {t("Уставки")}
            </Title>
            <Flex direction="column" gap="xs">
                {data?.map((x, index) => (
                    <ChannelThreshold
                        channelUnit={channelUnit}
                        index={index}
                        threshold={x}
                        channelId={channelId}
                        onEdit={() => setCreateNewThreshold(false)}
                    />
                ))}
            </Flex>

            {createNewThreshold && (
                <Card withBorder>
                    <Flex gap="lg" direction="column">
                        <Title order={6}>{t("Новая уставка")}</Title>
                        <ChannelThresholdForm
                            initialValue={{}}
                            channelId={channelId}
                            onCancel={() => setCreateNewThreshold(false)}
                            onSave={() => setCreateNewThreshold(false)}
                        />
                    </Flex>
                </Card>
            )}
            {!createNewThreshold && (
                <Button variant="default" rightIcon={<IconPlus />} onClick={() => setCreateNewThreshold(true)}>
                    {t("Добавить уставку")}
                </Button>
            )}
        </Flex>
    );
};

Notifications.displayName = "Notifications";
