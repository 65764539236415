import { Flex, Title, Text, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBrandTelegram } from "@tabler/icons-react";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../components/layout/Page";
import { useCreateTelegramBinding } from "../api/createTelegramBinding";
import { useTelegramBindings } from "../api/getTelegramBindings";
import { CreateTelegramBindingCard } from "../components/CreateTelegramBindingCard";
import { CreateTelegramBindingModal } from "../components/CreateTelegramBindingModal";
import { TelegramBindingCard } from "../components/TelegramBindingCard";

type TelegramBindingProps = {};

export const TelegramBinding: React.FC<TelegramBindingProps> = () => {
    const { t } = useTranslation();
    const { data } = useTelegramBindings();
    const [open, handlers] = useDisclosure(false);

    const handleConnectClick = async () => {
        handlers.open();
    };

    return (
        <>
            <Page
                header={{
                    title: t("Привязать Telegram"),
                    description: t("Привязать Telegram для получения уведомлений"),
                }}
            >
                <Flex direction="column" gap="lg">
                    <CreateTelegramBindingCard onConnectClick={handleConnectClick}></CreateTelegramBindingCard>
                    <Title order={3} weight={500} mt="lg">
                        {t("Подключенные аккаунты").toString()}
                    </Title>
                    {isEmpty(data) ? (
                        <Flex
                            direction="column"
                            p="lg"
                            align="center"
                            gap="lg"
                            sx={(theme) => ({ background: theme.colors.gray[1], borderRadius: theme.radius.md })}
                        >
                            <IconBrandTelegram size={48} />
                            <Text weight="bold">{t("Нет подключенных аккаунтов Telegram").toString()}</Text>
                        </Flex>
                    ) : (
                        <Flex direction="column" gap="xs">
                            {data?.map((telegramBinding) => (
                                <TelegramBindingCard data={telegramBinding} />
                            ))}
                        </Flex>
                    )}
                </Flex>
            </Page>
            <Modal title={t("Подключение Telegram").toString()} opened={open} onClose={handlers.close}>
                <CreateTelegramBindingModal onClose={handlers.close} />
            </Modal>
        </>
    );
};

TelegramBinding.displayName = "TelegramBinding";
