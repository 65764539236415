import React, { useState } from "react";
import DeviceCard from "./DeviceCard";
import { IconSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { Flex, Input } from "@mantine/core";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import { useDevices } from "src/features/devices/api/getDevices";
import { useDevicesStore } from "src/features/devices/store";

interface DevicesListProps {}

export const DevicesList: React.FC<DevicesListProps> = ({}) => {
    const { data: devices } = useDevices();
    const { selectDevice } = useDevicesStore();
    const selectedDevice = useSelectedDevice();
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");

    const fuse = new Fuse(devices ?? [], {
        keys: ["Name", "CCID", "Comment"],
    });

    return (
        <Flex
            direction="column"
            bg="white"
            sx={(theme) => ({
                overflow: "hidden",
                maxWidth: "400px",
                width: "100%",
                zIndex: 10,
                borderRight: `${1}px solid ${
                    theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
                }`,
            })}
        >
            <div className="p-2 mb-2">
                <div className="font-medium text-xl mb-4">{t("Мониторинг")}</div>
                <Input
                    value={filter}
                    onChange={(e: any) => setFilter(e.target.value)}
                    icon={<IconSearch size={20} />}
                    placeholder={t("Поиск")}
                />
            </div>

            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                              .search(filter)
                              .map((results) => (
                                  <DeviceCard
                                      key={results.item.Id}
                                      isSelected={selectedDevice?.Id === results.item.Id}
                                      device={results.item}
                                      onClick={selectDevice}
                                  />
                              ))
                        : devices?.map((device) => (
                              <DeviceCard
                                  key={device.Id}
                                  isSelected={selectedDevice?.Id === device.Id}
                                  device={device}
                                  onClick={selectDevice}
                              />
                          ))}
                </div>
            </div>
        </Flex>
    );
};
