import { Switch, Button, Input, Box, Stack, TextInput, Group } from "@mantine/core";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "src/components/layout/Page";
import { useToggleIEC } from "src/features/iec/api/toggleIEC";
import { useSetIECPort } from "src/features/iec/api/setIECPort";
import { getIECtable } from "src/api/IECtable";
import { useCurrentUser } from "../../auth";

type IECProps = {};

export const IEC: React.FC<IECProps> = () => {
    const { data: user } = useCurrentUser();
    const { t } = useTranslation();
    const toggleIECMutation = useToggleIEC();
    const setIECPortMutation = useSetIECPort();

    const [iecPort, setIecPort] = useState<string>(String(user?.IECPort));
    useEffect(() => {
        setIecPort(String(user?.IECPort));
    }, [user]);

    if (!user) {
        return null;
    }

    function handleIECPortChange() {
        setIECPortMutation.mutateAsync({
            UserId: user!.Id,
            Port: isNaN(Number(iecPort)) ? 0 : Number(iecPort),
        });
    }

    return (
        <>
            <Page
                header={{
                    title: t("МЭК-104"),
                    description: t("Управление ретрансляцией по протоколу МЭК-104"),
                }}
            >
                <Stack>
                    <Input.Wrapper label={t("Включить МЭК-104")}>
                        <Switch
                            sx={{ display: "flex" }}
                            size="lg"
                            onLabel="ON"
                            offLabel="OFF"
                            checked={user.IEC}
                            onChange={(event) => {
                                toggleIECMutation.mutateAsync({
                                    UserId: user.Id,
                                    IEC: event.currentTarget.checked,
                                });
                            }}
                        ></Switch>
                    </Input.Wrapper>

                    {user.IEC ? (
                        <>
                            <Group align="end">
                                <TextInput
                                    disabled
                                    maw={200}
                                    value={iecPort}
                                    onChange={(e) => {
                                        setIecPort(e.currentTarget.value);
                                    }}
                                    label={t("Порт МЭК-104")}
                                ></TextInput>
                                {/* <Button onClick={handleIECPortChange}>{t("Применить")}</Button> */}
                            </Group>
                            <Input.Wrapper label={t("Таблица портов МЭК-104")}>
                                <Box>
                                    <Button
                                        onClick={() => {
                                            const tableSettings = { UserId: user.Id };
                                            const filename = "Таблица портов МЭК-104_" + user.UserName;
                                            getIECtable(tableSettings, filename);
                                        }}
                                    >
                                        {t("Скачать")}
                                    </Button>
                                </Box>
                            </Input.Wrapper>
                        </>
                    ) : null}
                </Stack>
            </Page>
        </>
    );
};

IEC.displayName = "IEC";
