import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type PauseFirmwareCommand = {
    Id: number | null;
};

export const pauseFirmware = (command: PauseFirmwareCommand) => {
    return axios.post(`/firmware/${command.Id}/pause`, command);
};

type UsePauseFirmwareOptions = {
    config?: MutationConfig<typeof pauseFirmware>;
};

export const usePauseFirmware = ({ config }: UsePauseFirmwareOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Запрос уже был отправлен"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("/firmware");
            showNotification({
                title: t("Успех!"),
                message: t("Прошивка устройства остановлена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: pauseFirmware,
    });
};
