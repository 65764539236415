import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type CreateTelegramBindingCommand = {
    Name: string;
};

export const createTelegramBinding = (data: CreateTelegramBindingCommand) => {
    return axios.post(`telegram-bindings`, data);
};

type UseCreateTelegramBindingOptions = {
    config?: MutationConfig<typeof createTelegramBinding>;
};

export const useCreateTelegramBinding = ({ config }: UseCreateTelegramBindingOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t(""),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("telegram-bindings");
            showNotification({
                title: t("Успех!"),
                message: t(""),
                color: "teal",
            });
        },
        ...config,
        mutationFn: createTelegramBinding,
    });
};
