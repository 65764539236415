export interface FirmwareDto {
    Id: number;
    Name: string;
    Comment: string | null;
    Content: File | null;
}

export enum FirmwareStateEnum {
    Waiting = 1, //1. Необходимо прошить
    Running = 2, //2. Прошивка запущена
    Sending = 3, //3. Отправка страниц
    Finishing = 4, //4. Завершение прошивки
    Success = 5, //5. Успешно завершена
    Error = 6, //6. Ошибка
    CancelledAutomatically = 7, //7. Прошивка отменена (потому что в очереди появилась более новая прошивка)
    CancelledManually = 8, //8. Прошивка отменена вручную
}

export const statusLabels = {
    0: "Прошивка не требуется",
    1: "Ожидание прошивки",
    2: "Старт прошивки",
    3: "Отправка страниц",
    4: "Завершение прошивки",
    5: "Прошивка завершена успешно",
    6: "Прошивка завершена с ошибкой",
    7: "Прошивка отменена автоматически",
    8: "Прошивка отменена вручную",
};
