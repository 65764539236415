import { useMemo } from "react";
import { useUsers } from "../api/getUsers";
import { useUsersStore } from "../store";

export const useSelectedUser = () => {
    const { data: users } = useUsers();
    const selectedId = useUsersStore((state) => state.selectedId);
    const selectedUser = useMemo(() => users?.find((x) => x.Id === selectedId), [selectedId, users]);
    return selectedUser;
};
