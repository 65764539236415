import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import { FirmwareDto } from "../types/index";

interface CardSelectIndicatorProps {
    $selected: boolean;
    $position: "right" | "left";
}

export const Card = styled.div((props) => ({
    ...tw`cursor-pointer border-b border-gray-200 bg-white transition-colors hover:bg-gray-100 relative`,
    minWidth: "250px",
}));

const CardSelectIndicator = styled.div<CardSelectIndicatorProps>(({ $selected, $position }) => ({
    ...tw`absolute transition`,
    top: "8px",
    bottom: "8px",
    [$position]: 0,
    width: "3px",
    borderRadius: $position === "left" ? "0 3px 3px 0" : "3px 0 0 3px",
    ...($selected ? tw`bg-black` : tw`bg-transparent`),
    transition: "all 250ms ease",
}));

interface FirmwareCardProps {
    firmware: FirmwareDto;
    onClick: (firmware: FirmwareDto) => void;
    isSelected: boolean;
}

const FirmwareCard: React.FC<FirmwareCardProps> = ({ firmware, onClick, isSelected }) => {
    function onCardClick() {
        onClick(firmware);
    }

    return (
        <Card style={{ minWidth: "275px" }} className="group py-2 pr-3 pl-4" onClick={onCardClick}>
            <CardSelectIndicator $selected={isSelected} $position="left" />
            <CardSelectIndicator $selected={isSelected} $position="right" />

            <div className="flex items-center justify-between font-medium text-sm mb-2">{firmware.Name}</div>
        </Card>
    );
};

export default FirmwareCard;
