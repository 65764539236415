import React from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, number, boolean, date} from "yup";
import { NumberInput, Button, Stack, Switch, Input, Badge } from "@mantine/core";
import { DimensionParametersDto, DimensionStateEnum } from "src/features/devices/types";
import { useForm, yupResolver } from "@mantine/form";
import { SaveDimensionParametersCommand, useSaveDimensionParameters } from "../api/saveDimensionParameters";
import { DatePicker, TimeInput } from "@mantine/dates";
import { safeParseDate } from "src/utils/date";

const statusLabel = {
    [DimensionStateEnum.Waiting]: "Ожидает расчета",
    [DimensionStateEnum.Calculated]: "Расчитано",
    [DimensionStateEnum.CalculationError]: "Ошибка при расчете",
    [DimensionStateEnum.None]: "Расчета не было",
};

const SaveDimensionParametersCommandSchema: SchemaOf<SaveDimensionParametersCommand> = object({
    DeviceId: number().required(),
    DimensionEnabled: boolean().required(),
    Height: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
    LengthDimension: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
    DimensionDate: date()
    .transform((value) => (!(value instanceof Date) ? undefined : value))
    .optional(),
    DimensionTime: date()
    .transform((value) => (!(value instanceof Date) ? undefined : value))
    .optional(),
});

interface DimensionParametersDrawerProps {
    setOpen: (value: boolean) => void;
    deviceId: number;
    dimensionParameters: DimensionParametersDto;
}

export const DimensionParametersDrawer: React.FC<DimensionParametersDrawerProps> = ({ setOpen, deviceId, dimensionParameters }) => {
    const { t } = useTranslation();
    const saveMutation = useSaveDimensionParameters();
    const form = useForm<SaveDimensionParametersCommand>({
        initialValues: {
            DeviceId: deviceId,
            ...dimensionParameters,
            DimensionDate: safeParseDate(
                dimensionParameters.DimensionDate
            ),
            DimensionTime: safeParseDate(
                dimensionParameters.DimensionTime
            ),
        },
        validate: yupResolver(SaveDimensionParametersCommandSchema),
    });

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    return (
        <form
            onSubmit={form.onSubmit(async (values) => {
                await saveMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <div className="flex-1 overflow-y-scroll">
                <div>
                    <Stack>
                        <Switch
                            label={t("Контроль габарита")}
                            {...form.getInputProps("DimensionEnabled", { type: "checkbox" })}
                        />
                        <Input.Wrapper label={t("Текущий статус")}>
                            <Badge variant="light" color="blue" sx={{ width: "100%" }} radius="sm" size="lg">
                                {t(statusLabel[dimensionParameters.DimensionState? dimensionParameters.DimensionState: DimensionStateEnum.None])}
                            </Badge>
                        </Input.Wrapper>
                        <NumberInput
                            label={t("Измерение высоты подвеса провода, м")}
                            step={0.05}
                            precision={2}
                            min = {3}
                            max={100}
                            {...form.getInputProps("Height")}
                        />
                        <NumberInput
                            label={t("Расстояние точки измерения от ближайшей опоры, м")}
                            step={0.05}
                            precision={2}
                            min = {0}
                            max={800}
                            {...form.getInputProps("LengthDimension")}
                        />

                        <DatePicker
                            label={t("Дата измерения")}
                            {...form.getInputProps("DimensionDate")}
                        >
                        </DatePicker>
                        <TimeInput
                            label={t("Время измерения")}
                            {...form.getInputProps("DimensionTime")}
                        ></TimeInput>

                    </Stack>
                    <div className="space-x-3 mt-8 flex justify-end">
                        <Button onClick={handleClose}>{t("Отмена")}</Button>
                        <Button type="submit">{t("Сохранить")}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};
