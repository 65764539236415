import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type SetIECPortCommand = {
    UserId: number;
    Port: number;
};

export const setIECPort = (data: SetIECPortCommand) => {
    return axios.post(`/IEC/${data.UserId}/set-port`, data);
};

type UseSetIECPortOptions = {
    config?: MutationConfig<typeof setIECPort>;
};

export const useSetIECPort = ({ config }: UseSetIECPortOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка установки порта МЭК-104!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users-me");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно установлен порт МЭК-104!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: setIECPort,
    });
};
