import { Button, Card, Flex, Title } from "@mantine/core";
import React from "react";
import { TelegramLogo } from "./TelegramLogo";
import { useTranslation } from "react-i18next";

type CreateTelegramBindingCardProps = {
    onConnectClick: () => void;
};

export const CreateTelegramBindingCard: React.FC<CreateTelegramBindingCardProps> = ({ onConnectClick }) => {
    const { t } = useTranslation();
    return (
        <Card withBorder radius="md" p="lg" shadow="0 1px 2px 0 rgb(0 0 0 / 0.05)">
            <Flex justify={{ base: "center", xl: "space-between" }} align="center" wrap="wrap" gap="lg">
                <Flex
                    align="center"
                    justify="center"
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    <TelegramLogo size={48} />
                </Flex>
                <Title
                    order={5}
                    weight={500}
                    sx={{
                        flexGrow: 1,
                    }}
                    ta={{ base: "center", xl: "start" }}
                >
                    {t("Подключите Telegram для получения уведомлений!").toString()}
                </Title>
                <Button onClick={onConnectClick} variant="filled">
                    {t("Подключить!").toString()}
                </Button>
            </Flex>
        </Card>
    );
};

CreateTelegramBindingCard.displayName = "TelegramBindingCard";
