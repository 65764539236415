import { xor } from "lodash";
import { DeviceChannelDto, DeviceDto } from "src/features/devices/types";
import create from "zustand";

export type DevicesStore = {
    selectedId?: number;
    selectedChannelIds?: number[];
    selectDevice: (device: DeviceDto) => void;
    unselectDevice: () => void;
    toggleChannel: (deviceChannel: DeviceChannelDto) => void;
};

export const useDevicesStore = create<DevicesStore>((set) => ({
    selectDevice: (device: DeviceDto) => {
        set((state) => ({
            ...state,
            selectedId: device.Id,
            selectedChannelIds: device.DeviceChannels.map((x) => x.Id),
        }));
    },
    unselectDevice: () => {
        set((state) => ({
            ...state,
            selectedId: undefined,
            selectedChannelIds: undefined,
        }));
    },
    toggleChannel: (deviceChannel: DeviceChannelDto) => {
        set((state) => ({
            ...state,
            selectedChannelIds: xor(state.selectedChannelIds || [], [deviceChannel.Id]),
        }));
    },
}));
