import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type FirmwareDeviceCommand = {
    FirmwareId?: number | null;
    DeviceId?: number | null;
};

export const firmwareDevice = (command: FirmwareDeviceCommand) => {
    return axios.post(`/firmware/${command.FirmwareId}/devices/${command.DeviceId}/start`, command);
};

type UseFirmwareDeviceOptions = {
    config?: MutationConfig<typeof firmwareDevice>;
};

export const useFirmwareDevice = ({ config }: UseFirmwareDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка запроса на перепрошивку"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Запрос на перепрошивку получен"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: firmwareDevice,
    });
};
