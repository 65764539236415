import { Button, Drawer, Paper, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelectedDevice } from "../hooks/useSelectedDevice";
import { CreateDeviceParameterForm } from "./CreateDeviceParameterForm";
import { RemoteControlCard } from "./RemoteControlCard";

type RemoteControlProps = {};

export const RemoteControl: React.FC<RemoteControlProps> = () => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);
    const selectedDevice = useSelectedDevice();

    if (!selectedDevice) {
        return null;
    }

    return (
        <>
            <Paper className="mb-4" p="md" shadow="sm">
                <div className="flex justify-end mb-4">
                    <Button
                        leftIcon={<IconPlus />}
                        onClick={() => {
                            open();
                        }}
                        variant="outline"
                    >
                        {t("Добавить параметр")}
                    </Button>
                </div>
                <Stack>
                    {selectedDevice?.DeviceParameters.map((parameter) => (
                        <RemoteControlCard deviceId={selectedDevice.Id} parameter={parameter} />
                    ))}
                </Stack>
            </Paper>
            <Drawer
                opened={opened}
                onClose={close}
                title={t("Добавить параметр")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateDeviceParameterForm deviceId={selectedDevice.Id} onClose={close} />
            </Drawer>
        </>
    );
};

RemoteControl.displayName = "RemoteControl";
