import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { UserDeviceDto } from "../types";

export interface GetUserDevicesQuery extends PagedRequest {
    UserId: number;
}

export const getUserDevices = (request: GetUserDevicesQuery): Promise<PagedCollection<UserDeviceDto>> => {
    return axios.post(`users/${request.UserId}/devices`, request);
};

type QueryFnType = typeof getUserDevices;

type UseUserDevicesOptions = {
    request: GetUserDevicesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useUserDevices = ({ request, config }: UseUserDevicesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["users/{UserId}/devices", request],
        queryFn: () => getUserDevices(request),
    });
};
