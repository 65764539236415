import { Badge, Button, Checkbox, Input, NumberInput, Stack, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, yupResolver } from "@mantine/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { IceV2ConstantComponentDto, IceV2ConstantComponentStatusEnum } from "src/features/devices/types";
import { SchemaOf, object, number, date, boolean } from "yup";
import { safeParseDate } from "../../../utils/date";
import { SaveIceV2ConstantComponentCommand, useSaveIceV2ConstantComponent } from "../api/saveIceV2ConstantComponent";

const constantComponentStatusLabel = {
    [IceV2ConstantComponentStatusEnum.None]: "Ожидает расчета",
    [IceV2ConstantComponentStatusEnum.Calculated]: "Расчитано",
    [IceV2ConstantComponentStatusEnum.CalculationError]: "Ошибка при расчете (температура ниже гололеда)",
    [IceV2ConstantComponentStatusEnum.SetManually]: "Установлена вручную",
};

const SaveIceV2ConstantComponentCommandSchema: SchemaOf<SaveIceV2ConstantComponentCommand> = object({
    DeviceId: number().required(),
    IceV2ConstantComponentWindow: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ConstantComponent: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ConstantComponentWindowStartDate: date()
        .transform((value) => (!(value instanceof Date) ? undefined : value))
        .optional(),
    IceV2ConstantComponentIgnoreIceTemperature: boolean(),
});

type IceV2ConstantComponentDrawerProps = {
    setOpen: (value: boolean) => void;
    deviceId: number;
    iceV2ConstantComponent: IceV2ConstantComponentDto;
};

export const IceV2ConstantComponentDrawer: React.FC<IceV2ConstantComponentDrawerProps> = ({
    deviceId,
    setOpen,
    iceV2ConstantComponent,
}) => {
    const { t } = useTranslation();
    const saveMutation = useSaveIceV2ConstantComponent();
    const form = useForm<SaveIceV2ConstantComponentCommand>({
        initialValues: {
            DeviceId: deviceId,
            ...iceV2ConstantComponent,
            IceV2ConstantComponentWindowStartDate: safeParseDate(
                iceV2ConstantComponent.IceV2ConstantComponentWindowStartDate
            ),
        },
        validate: yupResolver(SaveIceV2ConstantComponentCommandSchema),
    });

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    return (
        <form
            onSubmit={form.onSubmit(async (values) => {
                await saveMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <div className="flex-1 overflow-y-scroll">
                <div>
                    <Stack>
                        <Input.Wrapper label={t("Текущий статус")}>
                            <Badge variant="light" color="blue" sx={{ width: "100%" }} radius="sm" size="lg">
                                {t(constantComponentStatusLabel[iceV2ConstantComponent.IceV2ConstantComponentStatus])}
                            </Badge>
                        </Input.Wrapper>

                        <DatePicker
                            label={t("Дата начала расчета постоянной составляющей")}
                            {...form.getInputProps("IceV2ConstantComponentWindowStartDate")}
                        />
                        <NumberInput
                            required={false}
                            label={t("Размер окна для расчета постоянной составляющей")}
                            step={1000}
                            {...form.getInputProps("IceV2ConstantComponentWindow")}
                        />
                        <NumberInput
                            required={false}
                            label={t("Постоянная составляющая")}
                            step={1}
                            precision={8}
                            {...form.getInputProps("IceV2ConstantComponent")}
                        />
                        <Checkbox
                            label={t("Игнорировать температуру образования гололеда")}
                            {...form.getInputProps("IceV2ConstantComponentIgnoreIceTemperature", { type: "checkbox" })}
                        />
                    </Stack>
                    <div className="space-x-3 mt-8 flex justify-end">
                        <Button onClick={handleClose}>{t("Отмена")}</Button>
                        <Button type="submit">{t("Сохранить")}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};
