import React, { useState } from "react";
import UserCard from "./UserCard";
import { IconSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { Input } from "@mantine/core";
import { useUsers } from "../api/getUsers";
import { useUsersStore } from "../store";
import { useSelectedUser } from "../hooks/useSelectedUser";

interface UsersListProps {}

const UsersList: React.FC<UsersListProps> = () => {
    const { data: users } = useUsers();
    const { selectUser } = useUsersStore();
    const selectedUser = useSelectedUser();
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");

    const fuse = new Fuse(users ?? [], {
        keys: ["Name"],
    });

    return (
        <div className="w-full h-full flex flex-col overflow-hidden bg-white">
            <div className="p-2 mb-2">
                <div className="font-medium text-xl mb-4">{t("Пользователи")}</div>
                <Input
                    value={filter}
                    onChange={(e: any) => setFilter(e.target.value)}
                    icon={<IconSearch size={20} />}
                    placeholder={t("Поиск")}
                />
            </div>

            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                              .search(filter)
                              .map((results) => (
                                  <UserCard
                                      key={results.item.Id}
                                      isSelected={selectedUser?.Id === results.item.Id}
                                      user={results.item}
                                      onClick={selectUser}
                                  />
                              ))
                        : null}
                    {users?.map((user) => (
                        <UserCard
                            key={user.Id}
                            isSelected={selectedUser?.Id === user.Id}
                            user={user}
                            onClick={selectUser}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UsersList;
