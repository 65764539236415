export type LoginResponse = {
    User: UserDto;
    Token: string;
};

export interface UserDto {
    Id: number;
    UserName: string;
    Email: string;
    Role: RoleEnum;
    IEC: boolean;
    IECPort: number;
    Language: LanguageEnum;
}

export enum RoleEnum {
    Admin = 1,
    User = 2,
}

export enum LanguageEnum {
    Russian = 1,
    English = 2,
    Turkish = 3,
    Persian = 4,
}
