import { Box } from "@mantine/core";
import React from "react";

type MainLayoutProps = {
    children: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                width: "100%",
                height: "100vh",
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
            })}
        >
            {children}
        </Box>
    );
};
