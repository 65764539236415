import { useMutation } from "react-query";

import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type UpdateDeviceCommand = {
    Id: number;
    Name: string;
    Comment: string;
    Latitude?: number | null;
    Longitude?: number | null;
    DeviceModelId: number;
    AutoTimeZoneDetection: boolean;
    AutoTimeZoneSeasonChanging: boolean;
    SummerTimeZoneChanging?: Date | null;
    WinterTimeZoneChanging?: Date | null;
    TimeZone?: number | null;
};

export const updateDevice = (command: UpdateDeviceCommand): Promise<EntityId<number>> => {
    return axios.put(`/devices`, command);
};

type UseUpdateDeviceOptions = {
    config?: MutationConfig<typeof updateDevice>;
};

export const useUpdateDevice = ({ config }: UseUpdateDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка обновления устройства!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно обновлена информация об устройстве!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: updateDevice,
    });
};
