import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { LanguageEnum } from "src/features/auth";
import storage from "src/utils/storage";

export type ChangeLanguageCommand = {
    UserId: number;
    LanguageId: number;
};

export const changeLanguage = (data: ChangeLanguageCommand): Promise<EntityId<number>> => {
    return axios.post(`/users/${data.UserId}/change-language`, data);
};

type UseChangeLanguageOptions = {
    config?: MutationConfig<typeof changeLanguage>;
};

export const useChangeLanguage = ({ config }: UseChangeLanguageOptions = {}) => {
    const { t, i18n } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения языка по умолчанию"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: (_, variables) => {
            var language = variables.LanguageId === LanguageEnum.Russian ? "ru" : variables.LanguageId === LanguageEnum.English ? "en" : variables.LanguageId === LanguageEnum.Turkish ? "tr" : "ir";
            i18n.changeLanguage(language);
            storage.setLanguage(language);
            showNotification({
                title: t("Успех!"),
                message: t("Язык по умолчанию успешно изменен"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: changeLanguage,
    });
};
