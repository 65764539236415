import { Button } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider } from "formik";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { DeviceDto } from "src/features/devices/types";
import { getIceV2File, IIceV2Settings } from "src/api/iceV2";
import { getUndefinedDevicesFile, IUndefinedDeviceSettings } from "src/api/undefinedDevice";

type UndefinedDeviceFileSaverDrawerProps = {
    onComplete: () => void;
};

export const UndefinedDeviceFileSaverDrawer: React.FC<UndefinedDeviceFileSaverDrawerProps> = ({ onComplete }) => {
    const { t } = useTranslation();

    const formik = useFormik<IUndefinedDeviceSettings>({
        initialValues: {
            StartDt: dayjs().subtract(2, "day").toDate(),
            EndDt: dayjs().toDate(),
        },

        onSubmit: async (values) => {
            const filename = `undefned device report`;
            await getUndefinedDevicesFile(values, `${filename}_${dayjs().format("YYYYMMDDHHmm")}`);
            onComplete();
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div style={{ marginBottom: "16px" }}>
                    <DatePicker
                        value={formik.values.StartDt}
                        maxDate={formik.values.EndDt}
                        inputFormat="DD.MM.YYYY"
                        onChange={(date) => formik.setFieldValue("StartDt", date)}
                        label={t("Начало интервала")}
                    />
                </div>

                <div style={{ marginBottom: "16px" }}>
                    <DatePicker
                        value={formik.values.EndDt}
                        minDate={formik.values.StartDt}
                        inputFormat="DD.MM.YYYY"
                        onChange={(date) => formik.setFieldValue("EndDt", date)}
                        label={t("Конец интервала")}
                    />
                </div>

                <div>
                    <Button type="submit">{t("Скачать")}</Button>
                </div>
            </form>
        </FormikProvider>
    );
};

UndefinedDeviceFileSaverDrawer.displayName = "UndefinedDeviceFileSaverDrawer";
