import Axios, { AxiosRequestConfig } from "axios";

import storage from "../utils/storage";
import { API_URL } from "src/config";

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();
    if (token) {
        config.headers!.authorization = `${token}`;
    }
    config.headers!.Accept = "application/json";
    return config;
}

export const http = Axios.create({
    baseURL: API_URL,
});

http.interceptors.request.use(authRequestInterceptor);
http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        // useNotificationStore.getState().addNotification({
        //     type: "error",
        //     title: "Error",
        //     message,
        // });

        return Promise.reject(error);
    }
);
