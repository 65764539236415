import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export interface CreateDeviceParameterCommand {
    ParameterId: number;
    DeviceId: number;
}

export const createDeviceParameter = (command: CreateDeviceParameterCommand) => {
    return axios.post(`/devices/${command.DeviceId}/device-parameters`, command);
};

type UseCreateDeviceParameterOptions = {
    config?: MutationConfig<typeof createDeviceParameter>;
};

export const useCreateDeviceParameter = ({ config }: UseCreateDeviceParameterOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при создании датчика устройства!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно создан датчик устройства!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createDeviceParameter,
    });
};
