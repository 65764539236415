import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type SaveDimensionParametersCommand = {
    DeviceId: number;
    DimensionEnabled: boolean;
    Height?: number | null;
    LengthDimension?: number | null;
    DimensionDate?: Date | string;
    DimensionTime?: Date | string;
};

export const saveDimensionParameters = (data: SaveDimensionParametersCommand) => {
    return axios.post(`/devices/${data.DeviceId}/dimension-parameters`, data);
};

type UseSaveDimensionParametersOptions = {
    config?: MutationConfig<typeof saveDimensionParameters>;
};

export const useSaveDimensionParameters = ({ config }: UseSaveDimensionParametersOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка сохранения параметров!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("break-detection");
            showNotification({
                title: t("Успех!"),
                message: t("Параметры успешно сохранены!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: saveDimensionParameters,
    });
};
