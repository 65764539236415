import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { IceV2CalculationValueDto } from "../types";

export type GetIceV2CalculationValuesQuery = PagedRequest & {
    DeviceId: number;
};

export const getIceV2CalculationValues = (
    query: GetIceV2CalculationValuesQuery
): Promise<PagedCollection<IceV2CalculationValueDto>> => {
    return axios.get(`/devices/${query.DeviceId}/ice-v2-calculation-values`, {
        params: {
            ...query,
        },
    });
};

type QueryFnType = typeof getIceV2CalculationValues;

type UseIceV2CalculationValuesOptions = {
    query: GetIceV2CalculationValuesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useIceV2CalculationValues = ({ query, config }: UseIceV2CalculationValuesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["/devices/[query.deviceId]/ice-v2-calculation-values", query.DeviceId, query.Skip, query.Take],
        queryFn: () => getIceV2CalculationValues(query),
    });
};
