import ReactDOMServer from "react-dom/server";
import React from "react";
import YandexMap from "./YandexMap";
import { DeviceDto } from "src/features/devices/types";
import { Marker } from "./Marker";
import ymaps from "yandex-maps";

export default class PositionMarker {
    private map: YandexMap;
    private device: DeviceDto;
    private layer: ymaps.Placemark;
    private ymaps: typeof ymaps;

    constructor(map: YandexMap, device: DeviceDto, clickHandler: any) {
        this.ymaps = map.ymapsAPI;
        this.map = map;
        this.device = { ...device };
        this.layer = this.createPlacemark(this.device);
        this.layer.events.add("click", (event) => {
            if (clickHandler) {
                const target = (event as ymaps.IEvent).get("target");
                if (target) {
                    const placemark = target as ymaps.Placemark;
                    clickHandler(placemark.properties.getAll());
                }
            }
        });
    }

    public getBounds() {
        return this.layer.geometry?.getBounds();
    }

    public focus() {
        const center = this.layer.geometry?.getCoordinates();
        this.map.setCenter(center);
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayerToClusterer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayerFromClusterer(this.layer);
    }

    private createLayout(template: any) {
        return this.map.ymapsAPI.templateLayoutFactory.createClass(template);
    }

    public startEditing(updateGeometryCallback: any) {
        this.layer.editor.startEditing();
        updateGeometryCallback();
        if (this.layer.geometry) {
            this.layer.geometry.events.add("change", updateGeometryCallback);
        }
    }

    public stopEditing(updateGeometryCallback: any) {
        this.layer.editor.stopEditing();
        if (this.layer.geometry) {
            this.layer.geometry.events.remove("change", updateGeometryCallback);
        }
    }

    private createPlacemark(device: DeviceDto) {
        let placemarkColor = "#1070ca";
        const properties = {
            Id: device.Id,
            Name: device.Name,
            needLabel: true,
            IconSize: 24,
            Color: placemarkColor,
            clusterCaption: device.Name,
            balloonContentBody: ReactDOMServer.renderToStaticMarkup(<div>{device.Comment || "-"}</div>),
        };

        const options: ymaps.IPlacemarkOptions = {
            hasBalloon: false,
            iconLayout: "default#imageWithContent",
            iconContentLayout: this.createLayout(ReactDOMServer.renderToStaticMarkup(<Marker device={device} />)),
            iconColor: placemarkColor,
            iconContentSize: [280, 32],
            iconImageHref: "",
            iconImageSize: [32, 32],
            iconImageOffset: [-32 / 2, -32 / 2],
        };

        return new this.ymaps.Placemark([device.Latitude, device.Longitude], properties, options);
    }
}
