import { useQuery } from "react-query";
import { IceV2ParametersDto } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type GetIceV2ParametersQuery = {
    deviceId: number;
};

export const getIceV2Parameters = (query: GetIceV2ParametersQuery): Promise<IceV2ParametersDto> => {
    return axios.get(`/devices/${query.deviceId}/ice-v2-parameters`);
};

type QueryFnType = typeof getIceV2Parameters;

type UseIceV2ParametersOptions = {
    config?: QueryConfig<QueryFnType>;
    query: GetIceV2ParametersQuery;
};

export const useIceV2Parameters = ({ query, config }: UseIceV2ParametersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["ice-v2-parameters", query.deviceId],
        queryFn: () => getIceV2Parameters(query),
        ...config,
    });
};
