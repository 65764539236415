import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Notification = {
    id: string;
    type: "info" | "warning" | "success" | "error";
    title: string;
    message?: string;
};

export type NotificationsState = {
    notifications: Notification[];
};

const initialState: NotificationsState = {
    notifications: [],
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        removeNotification(state, action: PayloadAction<string>) {
            state.notifications = state.notifications.filter((x) => x.id !== action.payload);
        },
    },
});

export const { removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
