import React, { useState } from "react";
import FirmwareCard from "./FirmwareCard";
import { IconSearch, IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { ActionIcon, Button, Drawer, Flex, Input, Tooltip } from "@mantine/core";
import { useFirmwares } from "../api/getFirmwares";
import { useFirmwaresStore } from "../store";
import { useSelectedFirmware } from "../hooks/useSelectedFirmware";
import { MainLink } from "src/components/Navbar/MainLink";
import { useDisclosure } from "@mantine/hooks";
import { CreateFirmwareDrawer } from "./CreateFirmwareDrawer";

interface FirmwaresListProps { }

const FirmwaresList: React.FC<FirmwaresListProps> = () => {
    const { data: firmwares } = useFirmwares();
    const { selectFirmware } = useFirmwaresStore();
    const selectedFirmware = useSelectedFirmware();
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");
    const [isCreateFirmwareDrawerOpen, setCreateFirmwareDrawerOpen] = useState(false);

    const fuse = new Fuse(firmwares ?? [], {
        keys: ["Name"],
    });

    return (
        <Flex
            direction="column"
            bg="white"
            sx={(theme) => ({
                overflow: "hidden",
                maxWidth: "400px",
                width: "100%",
                zIndex: 10,
                borderRight: `${1}px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
                    }`,
            })}
        >
            <div className="p-2 mb-2">
                <div className="font-medium text-xl mb-4">{t("Прошивки")}</div>
                <Button className="mb-4"
                    onClick={() => setCreateFirmwareDrawerOpen(true)}
                    leftIcon={<IconPlus size={20} />}>
                    {t("Добавить прошивку")}
                </Button>
                <Input
                    value={filter}
                    onChange={(e: any) => setFilter(e.target.value)}
                    icon={<IconSearch size={20} />}
                    placeholder={t("Поиск")}
                />
            </div>

            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                            .search(filter)
                            .map((results) => (
                                <FirmwareCard
                                    key={results.item.Id}
                                    isSelected={selectedFirmware?.Id === results.item.Id}
                                    firmware={results.item}
                                    onClick={selectFirmware}
                                />
                            ))
                        : firmwares?.map((firmware) => (
                            <FirmwareCard
                                key={firmware.Id}
                                isSelected={selectedFirmware?.Id === firmware.Id}
                                firmware={firmware}
                                onClick={selectFirmware}
                            />
                        ))}
                </div>
            </div>
            <Drawer
                opened={isCreateFirmwareDrawerOpen}
                onClose={() => setCreateFirmwareDrawerOpen(false)}
                title={t("Добавить прошивку")}
                padding="lg"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateFirmwareDrawer onComplete={() => setCreateFirmwareDrawerOpen(false)} />
            </Drawer>
        </Flex>

    );
};

export default FirmwaresList;
