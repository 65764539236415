import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import { YMaps } from "@pbe/react-yandex-maps";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import { store } from "../store";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { Notifier } from "src/notifications/Notifier";
import { queryClient } from "src/lib/react-query";
import { AuthProvider } from "src/lib/auth";
import { useLocalStorage } from "@mantine/hooks";
import { InternalServerError } from "../features/error-pages";

type AppProviderProps = {
    children: React.ReactNode;
};

const THEME_KEY = "service-energy-color-scheme";

export const AppProvider = ({ children }: AppProviderProps) => {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: THEME_KEY,
        defaultValue: "light",
    });

    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                withNormalizeCSS
                theme={{
                    primaryColor: "dark",
                    fontFamily: "Montserrat, sans-serif",
                }}
            >
                <ErrorBoundary FallbackComponent={InternalServerError}>
                    {/* <CssBaseline /> */}

                    <NotificationsProvider>
                        <QueryClientProvider client={queryClient}>
                            <Provider store={store}>
                                <YMaps
                                    query={{
                                        mode: "debug",
                                        apikey: "a32c65bc-aed1-4438-87bf-f957063933b",
                                        load: "package.full",
                                    }}
                                    preload={true}
                                    enterprise={false}
                                >
                                    <Notifier />
                                    <AuthProvider>
                                        <Router>{children}</Router>
                                    </AuthProvider>
                                </YMaps>
                            </Provider>
                        </QueryClientProvider>
                    </NotificationsProvider>
                </ErrorBoundary>
            </MantineProvider>
        </ColorSchemeProvider>
    );
};
