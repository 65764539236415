import React from "react";
import { InfoForm } from "../components/Info/InfoForm";

const InfoView: React.FC = () => {
    return (
        <>
            <div className="flex flex-1"><InfoForm /></div>
        </>
    );
};

export default InfoView;
