import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { FirmwareDeviceDto } from "../types";

export interface GetDeviceFirmwaresQuery extends PagedRequest {
    DeviceId: number;
}

export const getDeviceFirmwares = (request: GetDeviceFirmwaresQuery): Promise<PagedCollection<FirmwareDeviceDto>> => {
    return axios.post(`devices/${request.DeviceId}/firmware`, request);
};

type QueryFnType = typeof getDeviceFirmwares;

type UseDeviceFirmwaresOptions = {
    request: GetDeviceFirmwaresQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useDeviceFirmwares = ({ request, config }: UseDeviceFirmwaresOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["firmware/{FirmwareId}/devices", request],
        queryFn: () => getDeviceFirmwares(request),
    });
};
