import { Button, Flex, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateTelegramBinding } from "../api/createTelegramBinding";

type CreateTelegramBindingModalProps = {
    onClose?: () => void;
};

export const CreateTelegramBindingModal: React.FC<CreateTelegramBindingModalProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const { mutateAsync } = useCreateTelegramBinding();
    const handleCreateClick = async () => {
        await mutateAsync({ Name: name });
        onClose?.();
    };

    return (
        <Flex direction="column" gap="lg">
            <TextInput
                label={t("Название чата (Например - Рабочий аккаунт)")}
                onChange={(e) => setName(e.currentTarget.value)}
                value={name}
            ></TextInput>
            <Button onClick={handleCreateClick}>{t("Подключить!")}</Button>
        </Flex>
    );
};

CreateTelegramBindingModal.displayName = "CreateTelegramBindingModal";
