import React from "react";
import { withYMaps } from "@pbe/react-yandex-maps";

import { DevicesList } from "../features/devices/components/DevicesList";
import { CurrentDevicePanel } from "src/features/devices/components/CurrentDevicePanel";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import MapComponent from "src/components/Monitoring/MapComponent";
import { useDevices } from "src/features/devices/api/getDevices";
import { Flex } from "@mantine/core";

interface Props {}

const MonitoringView: React.FC<Props> = () => {
    const selectedDevice = useSelectedDevice();
    const { data: devices } = useDevices();
    return (
        <Flex sx={{ flexGrow: 1, height: "100vh", width: "100%" }}>
            <DevicesList />
            <MapComponent devices={devices} />
            {selectedDevice ? <CurrentDevicePanel device={selectedDevice} /> : null}
        </Flex>
    );
};

export default withYMaps(MonitoringView);
