import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type ToggleIECEnabledCommand = {
    UserId: number;
    IEC: boolean;
};

export const toggleIEC = (data: ToggleIECEnabledCommand) => {
    return axios.post(`/IEC/${data.UserId}/toggle`, data);
};

type UseToggleIECOptions = {
    config?: MutationConfig<typeof toggleIEC>;
};

export const useToggleIEC = ({ config }: UseToggleIECOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка включения или выключения передачи по МЭК-104!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users-me");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно включена или выключена передача по МЭК-104!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: toggleIEC,
    });
};
