import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type DeleteTelegramBindingCommand = {
    Id: number;
};

export const deleteTelegramBinding = (data: DeleteTelegramBindingCommand) => {
    return axios.delete(`telegram-bindings/${data.Id}`);
};

type UseDeleteTelegramBindingOptions = {
    config?: MutationConfig<typeof deleteTelegramBinding>;
};

export const useDeleteTelegramBinding = ({ config }: UseDeleteTelegramBindingOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t(""),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("telegram-bindings");
            showNotification({
                title: t("Успех!"),
                message: t(""),
                color: "teal",
            });
        },
        ...config,
        mutationFn: deleteTelegramBinding,
    });
};
