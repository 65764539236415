import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Flex, Paper, Table, Tooltip } from "@mantine/core";
import { CreateDeviceChannelForm } from "src/features/devices/components/CreateDeviceChannelForm";
import { IconPlus } from "@tabler/icons-react";
import { useDevicesStore } from "src/features/devices/store";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import { DeviceChannelDto } from "src/features/devices/types";
import { ChannelsTableRow } from "./ChannelsTableRow";

interface ChannelsTableProps {
    onChannelClick: (channel: DeviceChannelDto) => void;
}

const ChannelsTable: React.FC<ChannelsTableProps> = ({ onChannelClick }) => {
    const selectedChannelIds = useDevicesStore((state) => state.selectedChannelIds);
    const selectedDevice = useSelectedDevice();
    const { t } = useTranslation();
    const [createDeviceChannelDrawerOpen, setCreateDeviceChannelDrawerOpen] = useState(false);

    if (!selectedDevice) {
        return null;
    }

    return (
        <>
            <Paper className="mb-4" p="md" shadow="sm">
                <div className="flex justify-end mb-4">
                    <Button
                        leftIcon={<IconPlus />}
                        onClick={() => {
                            setCreateDeviceChannelDrawerOpen(true);
                        }}
                        variant="outline"
                    >
                        {t("Добавить канал").toString()}
                    </Button>
                </div>
                <Table withColumnBorders>
                    <thead>
                        <tr>
                            <th 
                                className="w-4" 
                                style={{
                                    textAlign: "center", 
                                    boxSizing: "border-box"
                                }}
                            >
                                <Tooltip label="Выбрать все">
                                    <Flex
                                        style={{
                                            textAlign: "center", 
                                            backgroundColor: selectedChannelIds?.length === selectedDevice.DeviceChannels?.length ? "black" : "white",
                                            color: selectedChannelIds?.length === selectedDevice.DeviceChannels?.length ? "white" : "black",
                                            borderRadius: "5px",
                                            width: "100%",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            padding: "3px 5px 3px 5px",
                                            cursor: "pointer",
                                            margin: 3
                                        }}
                                        onClick={(event) => {
                                            if (selectedChannelIds?.length === selectedDevice.DeviceChannels?.length)
                                                selectedDevice.DeviceChannels.map((channel) => (onChannelClick(channel)))
                                            else
                                                selectedDevice.DeviceChannels.map((channel) => {
                                                    if (!selectedChannelIds?.includes(channel.Id))
                                                    onChannelClick(channel)
                                                })
                                        }} 
                                    >
                                        {t("График").toString()}
                                    </Flex>
                                </Tooltip>
                            </th>
                            <th>{t("Имя").toString()}</th>
                            <th className="w-36">
                                <Flex justify="center">{t("Значение").toString()}</Flex>
                            </th>
                            <th className="w-48">
                                <Flex justify="center">{t("Время").toString()}</Flex>
                            </th>
                            <th className="w-28">
                                <Flex justify="center">{t("Действия").toString()}</Flex>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedDevice.DeviceChannels.map((channel) => (
                            <ChannelsTableRow
                                channel={channel}
                                onChannelClick={onChannelClick}
                                checked={!!selectedChannelIds?.includes(channel.Id)}
                            />
                        ))}
                    </tbody>
                </Table>
            </Paper>
            <Drawer
                opened={createDeviceChannelDrawerOpen}
                onClose={() => setCreateDeviceChannelDrawerOpen(false)}
                title={t("Добавить канал")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateDeviceChannelForm deviceId={selectedDevice.Id} setOpen={setCreateDeviceChannelDrawerOpen} />
            </Drawer>
        </>
    );
};

export default ChannelsTable;
