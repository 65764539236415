import { useQuery } from "react-query";
import { IceV2ConstantComponentDto } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type GetIceV2ConstantComponentQuery = {
    deviceId: number;
};

export const getIceV2ConstantComponent = (
    query: GetIceV2ConstantComponentQuery
): Promise<IceV2ConstantComponentDto> => {
    return axios.get(`/devices/${query.deviceId}/ice-v2-constant-component`);
};

type QueryFnType = typeof getIceV2ConstantComponent;

type UseIceV2ConstantComponentOptions = {
    config?: QueryConfig<QueryFnType>;
    query: GetIceV2ConstantComponentQuery;
};

export const useIceV2ConstantComponent = ({ query, config }: UseIceV2ConstantComponentOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["ice-v2-constant-component", query.deviceId],
        queryFn: () => getIceV2ConstantComponent(query),
        ...config,
    });
};
