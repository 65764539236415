import { xor } from "lodash";
import { FirmwareDto } from "../types/index";
import create from "zustand";

export type FirmwaresStore = {
    selectedId?: number;
    selectFirmware: (firmware: FirmwareDto) => void;
    unselectFirmware: () => void;
};

export const useFirmwaresStore = create<FirmwaresStore>((set) => ({
    selectFirmware: (firmware: FirmwareDto) => {
        set((state) => ({
            ...state,
            selectedId: firmware.Id,
        }));
    },
    unselectFirmware: () => {
        set((state) => ({
            ...state,
            selectedId: undefined,
        }));
    },
}));
