import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export const deleteDevice = (id: number) => {
    return axios.delete(`/devices/${id}`);
};

type UseDeleteDeviceOptions = {
    config?: MutationConfig<typeof deleteDevice>;
};

export const useDeleteDevice = ({ config }: UseDeleteDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при удалении устройства"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Устройство удалено"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: deleteDevice,
    });
};
