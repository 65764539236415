// Russian [ru]
import dayjs from "dayjs";

const monthStandalone = "январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь".split("_");

const monthShortStandalone = "янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.".split("_");

function plural(word: any, num: any) {
    const forms = word.split("_");
    return num % 10 === 1 && num % 100 !== 11
        ? forms[0]
        : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
        ? forms[1]
        : forms[2]; // eslint-disable-line
}
function relativeTimeWithPlural(number: any, withoutSuffix: any, key: any) {
    const format = {
        mm: withoutSuffix ? "минута_минуты_минут" : "минуту_минуты_минут",
        hh: "час_часа_часов",
        dd: "день_дня_дней",
        MM: "месяц_месяца_месяцев",
        yy: "год_года_лет",
    };
    if (key === "m") {
        return withoutSuffix ? "минута" : "минуту";
    }

    // @ts-ignore
    return `${number} ${plural(format[key], +number)}`;
}

const locale = {
    name: "ru",
    weekdays: "воскресенье_понедельник_вторник_среда_четверг_пятница_суббота".split("_"),
    weekdaysShort: "вск_пнд_втр_срд_чтв_птн_сбт".split("_"),
    weekdaysMin: "вс_пн_вт_ср_чт_пт_сб".split("_"),
    months: monthStandalone,
    monthsShort: monthShortStandalone,
    weekStart: 1,
    formats: {
        LT: "H:mm",
        LTS: "H:mm:ss",
        L: "DD.MM.YYYY",
        LL: "D MMMM YYYY г.",
        LLL: "D MMMM YYYY г., H:mm",
        LLLL: "dddd, D MMMM YYYY г., H:mm",
    },
    relativeTime: {
        future: "через %s",
        past: "%s назад",
        s: "несколько секунд",
        m: relativeTimeWithPlural,
        mm: relativeTimeWithPlural,
        h: "час",
        hh: relativeTimeWithPlural,
        d: "день",
        dd: relativeTimeWithPlural,
        M: "месяц",
        MM: relativeTimeWithPlural,
        y: "год",
        yy: relativeTimeWithPlural,
    },
    ordinal: (n: any) => n,
};
// @ts-ignore
dayjs.locale(locale, null, true);

export default locale;
