import { Box } from "@mantine/core";
import React from "react";

type PageWrapperProps = {
    children: React.ReactNode;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
    return (
        <Box
            sx={(theme) => ({
                flexGrow: 1,
                overflowY: "auto",
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
            })}
        >
            {children}
        </Box>
    );
};

PageWrapper.displayName = "PageWrapper";
