import { useMemo } from "react";
import { useFirmwares } from "../api/getFirmwares";
import { useFirmwaresStore } from "../store";

export const useSelectedFirmware = () => {
    const { data: firmwares } = useFirmwares();
    const selectedId = useFirmwaresStore((state) => state.selectedId);
    const selectedFirmware = useMemo(() => firmwares?.find((x) => x.Id === selectedId), [selectedId, firmwares]);
    return selectedFirmware;
};
