import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Box, LoadingOverlay } from "@mantine/core";
import { SideNav } from "src/components/layout/SideNav";
import { MainLayout } from "src/components/layout/MainLayout";
import MonitoringView from "src/views/MonitoringView";
import ReportView from "src/views/ReportView";
import { ProfileRoutes } from "src/features/profile";
import AdminView from "src/views/AdminView";
import FirmwareView from "src/views/FirmwareView";
import IECView from "src/views/IECView";
import InfoView from "src/views/InfoView";

const App = () => {
    return (
        <MainLayout>
            <SideNav></SideNav>
            <Suspense
                fallback={
                    <LoadingOverlay loaderProps={{ variant: "bars" }} visible />
                    // <div className="h-full w-full flex items-center justify-center">
                    //   <Loader />
                    // </div>
                }
            >
                <Box
                    sx={{
                        paddingLeft: "81px",
                        flex: "1 1",
                        height: "100vh",
                        display: "flex",
                        width: "calc(100% - 81px)",
                    }}
                >
                    <Outlet />
                </Box>
            </Suspense>
        </MainLayout>
    );
};

export const protectedRoutes = [
    {
        path: "/",
        element: <App />,
        children: [
            // { path: '/discussions/*', element: <DiscussionsRoutes /> },
            // { path: '/users', element: <Users /> },
            { path: "/", element: <Navigate to="/monitoring" /> },
            { path: "/monitoring", element: <MonitoringView /> },
            { path: "/reports/*", element: <ReportView /> },
            { path: "/profile/*", element: <ProfileRoutes /> },
            { path: "/admin/*", element: <AdminView /> },
            { path: "/firmware/*", element: <FirmwareView /> },
            { path: "/info/*", element: <InfoView /> },
            { path: "/iec/*", element: <IECView /> },
            { path: "*", element: <Navigate to="/monitoring" /> },
        ],
    },
];
