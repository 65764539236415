import { useNavigate } from "react-router-dom";
import Container from "src/components/auth/Container";
import { LoginForm } from "../components/LoginForm";

export const Login = () => {
    const navigate = useNavigate();

    return (
        <Container className="background-pattern">
            <LoginForm
                onSuccess={() => {
                    navigate("/");
                }}
            />
        </Container>
    );
};
