import { Loader } from "@mantine/core";
import { UserDto, LoginResponse, getUser, login, register, RegisterCommand, LoginCommand, LanguageEnum } from "src/features/auth";
import i18n from "src/i18n";
import { initReactQueryAuth } from "src/lib/react-auth";
import storage from "src/utils/storage";

async function handleUserResponse(data: LoginResponse) {
    const { Token, User } = data;
    storage.setToken(Token);
    var language = User.Language === LanguageEnum.Russian ? "ru" : User.Language === LanguageEnum.English ? "en" : User.Language === LanguageEnum.Turkish ? "tr" : "ir";
    i18n.changeLanguage(language);
    storage.setLanguage(language);
    return User;
}

async function loadUser() {
    if (storage.getToken()) {
        const data = await getUser();
        return data;
    }
    return null;
}

async function loginFn(data: LoginCommand) {
    const response = await login(data);
    const user = await handleUserResponse(response);
    return user;
}

async function registerFn(data: RegisterCommand) {
    const response = await register(data);
    const user = await handleUserResponse(response);
    return user;
}

async function logoutFn() {
    storage.clearToken();
    window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
    loadUser,
    loginFn,
    registerFn,
    logoutFn,
    LoaderComponent() {
        return (
            <div className="w-screen h-screen flex justify-center items-center">
                <Loader variant="bars" />
            </div>
        );
    },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<UserDto | null, unknown, LoginCommand, RegisterCommand>(
    authConfig
);
