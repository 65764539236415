import { Paper, Title, Button, Group, Badge, Stack, Pagination, ActionIcon, Tooltip } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { UserDto } from "src/features/auth";
import { useUsersStore } from "../store";
import { useUserDevices } from "../../devices/api/getUserDevices";
import { DataTable } from "mantine-datatable";
import { useDevices } from "../../devices/api/getDevices";
import { DeviceDto } from "../../devices/types";
import { IconTrash } from "@tabler/icons-react";
import { useUnbindDevice } from "../../devices/api/unbindDevice";
import { useTranslation } from "react-i18next";

type CurrentUserPanelProps = {
    user: UserDto;
};

export const CurrentUserPanel: React.FC<CurrentUserPanelProps> = ({ user }) => {
    const { unselectUser } = useUsersStore();
    const { data: devices } = useDevices();
    const { mutateAsync } = useUnbindDevice();
    const { t } = useTranslation();

    const devicesDict = useMemo(
        () => devices?.reduce<Record<number, DeviceDto>>((acc, value) => ({ ...acc, [value.Id]: value }), {}),
        [devices]
    );

    const [activePage, setPage] = useState(1);
    const { data } = useUserDevices({
        request: {
            UserId: user.Id,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    return (
        <React.Fragment>
            <div className="overflow-auto h-full flex-1 mt-4 p-4 z-50 bg-gray-100">
                <Paper className="mb-4" p="md" shadow="sm">
                    <Stack spacing="xs">
                        <Group>
                            <Button variant="default" onClick={unselectUser}>
                                {t("Закрыть")}
                            </Button>
                        </Group>
                        <Group>
                            <Badge radius="xs" variant="filled">
                                {user.Email}
                            </Badge>
                            <Badge color="gray" radius="xs" variant="outline">
                                {user.Id}
                            </Badge>
                        </Group>
                        <Group position="apart">
                            <Title order={2}>{user.UserName}</Title>
                        </Group>
                    </Stack>
                </Paper>
                <Paper className="mb-4" p="md" shadow="sm">
                    {devicesDict && (
                        <DataTable
                            minHeight={150}
                            withBorder
                            borderRadius="sm"
                            withColumnBorders
                            striped
                            highlightOnHover
                            // provide data
                            records={data?.Page}
                            // define columns
                            columns={[
                                {
                                    accessor: "DeviceId",
                                    title: t("Устройство"),
                                    width: 100,
                                    render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                },
                                {
                                    accessor: "actions",
                                    title: t("Действия"),
                                    textAlignment: "right",
                                    render: (value) => (
                                        <Group spacing={4} position="right" noWrap>
                                            <Tooltip label={t("Удалить привязку")}>
                                                <ActionIcon
                                                    color="red"
                                                    onClick={() =>
                                                        mutateAsync({ DeviceId: value.DeviceId, UserId: value.UserId })
                                                    }
                                                >
                                                    <IconTrash size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    ),
                                },
                            ]}
                        />
                    )}

                    {data && data.Total ? (
                        <Group my="md" position="right">
                            <Pagination
                                page={activePage}
                                onChange={setPage}
                                total={Math.floor(data.Total / 100)}
                                withEdges
                            />
                        </Group>
                    ) : null}
                </Paper>
            </div>
        </React.Fragment>
    );
};
