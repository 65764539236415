import { Paper, Title, Button, Group, Stack, Pagination, ActionIcon, Tooltip, Popover, Text } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { useFirmwaresStore } from "../store";
import { DataTable } from "mantine-datatable";
import { useDevices } from "../../devices/api/getDevices";
import { DeviceDto } from "../../devices/types";
import { IconPlayerPause, IconTrash } from "@tabler/icons-react";
import { FirmwareDto, statusLabels } from "../types";
import { useFirmwareDevices } from "src/features/devices/api/getFirmwareDevices";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useDeleteFirmware } from "../api/deleteFirmware";
import dayjs from "dayjs";
import { title } from "process";
import { usePauseFirmware } from "../api/pauseFirmware";

type CurrentFirmwarePanelProps = {
    firmware: FirmwareDto;
};

export const CurrentFirmwarePanel: React.FC<CurrentFirmwarePanelProps> = ({ firmware }) => {
    const { unselectFirmware } = useFirmwaresStore();
    const { data: devices } = useDevices();
    const [deleteFirmwareOpened, deleteFirmwareHandlers] = useDisclosure(false);
    const { t } = useTranslation();
    const { mutateAsync } = usePauseFirmware();

    const deleteFirmwareMutation = useDeleteFirmware();

    const devicesDict = useMemo(
        () => devices?.reduce<Record<number, DeviceDto>>((acc, value) => ({ ...acc, [value.Id]: value }), {}),
        [devices]
    );

    const [activePage, setPage] = useState(1);
    const { data } = useFirmwareDevices({
        request: {
            FirmwareId: firmware.Id,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    return (
        <React.Fragment>
            <div className="overflow-auto h-full flex-1 mt-4 p-4 z-50 bg-gray-100">
                <Paper className="mb-4" p="md" shadow="sm">
                    <Stack spacing="xs">
                        <Group>
                            <Button variant="default" onClick={unselectFirmware}>
                                {t("Закрыть")}
                            </Button>
                        </Group>
                        <Group position="apart">
                            <Title order={2}>{firmware.Name}</Title>
                            <Group>
                                <Popover
                                    opened={deleteFirmwareOpened}
                                    onClose={deleteFirmwareHandlers.close}
                                    position="bottom-end"
                                    transition="pop-top-right"
                                >
                                    <Popover.Target>
                                        <Tooltip label={t("Удалить прошивку")} withArrow>
                                            <ActionIcon
                                                onClick={deleteFirmwareHandlers.open}
                                                size="lg"
                                                variant="outline"
                                                color="red"
                                            >
                                                <IconTrash size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <Button
                                            color="red"
                                            variant="filled"
                                            size="sm"
                                            onClick={() => {
                                                deleteFirmwareMutation.mutateAsync(firmware.Id);
                                            }}
                                        >
                                            {t("Удалить прошивку!")}
                                        </Button>
                                    </Popover.Dropdown>
                                </Popover>
                            </Group>
                        </Group>
                        <Group>
                            <Text color="gray" size="md">
                                {firmware.Comment}
                            </Text>
                        </Group>
                    </Stack>
                </Paper>
                <Paper className="mb-4" p="md" shadow="sm">
                    {devicesDict && (
                        <DataTable
                            minHeight={150}
                            withBorder
                            borderRadius="sm"
                            withColumnBorders
                            striped
                            highlightOnHover
                            // provide data
                            records={data?.Page}
                            // define columns
                            columns={[
                                {
                                    accessor: "DeviceId",
                                    title: t("Устройство"),
                                    textAlignment: "center",
                                    render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                },
                                {
                                    accessor: "CCID",
                                    title: "CCID",
                                    textAlignment: "center",
                                    render: ({ DeviceId }) => devicesDict[DeviceId].CCID,
                                },
                                {
                                    accessor: "Status",
                                    title: t("Статус прошивки"),
                                    textAlignment: "center",
                                    render: ({ Status }) => (t(statusLabels[Status])),
                                },
                                {
                                    accessor: "PageNumber",
                                    title: t("Страницы"),
                                    textAlignment: "center",
                                    render: ({ PageNumber, Pages }) => (Pages ? `${PageNumber} / ${Pages}` : PageNumber),

                                },
                                {
                                    accessor: "StartDate",
                                    title: t("Начало"),
                                    textAlignment: "center",
                                    render: ({ StartDate }) => (dayjs(StartDate).format("DD.MM.YY HH:mm")),
                                },
                                {
                                    accessor: "Status",
                                    title: t("Окончание"),
                                    textAlignment: "center",
                                    render: ({ EndDate }) => (EndDate ? dayjs(EndDate).format("DD.MM.YY HH:mm") : EndDate),
                                },
                                {
                                    accessor: "actions",
                                    title: t("Действия"),
                                    textAlignment: "center",
                                    render: ({ Status, Id, Pause }) => (
                                        ((Status != 5) && (Status != 6) && (Status != 7) && (Status != 8) && (!Pause)) ?
                                            <Group spacing={4} position="center" noWrap>
                                                <Tooltip label={t("Остановить прошивку")}>
                                                    <ActionIcon
                                                        color="black"
                                                        onClick={() =>
                                                            mutateAsync({ Id: Id })
                                                        }
                                                    >
                                                        <IconTrash size={20} />
                                                    </ActionIcon>
                                                </Tooltip>
                                            </Group> : null
                                    ),
                                },
                            ]}
                        />
                    )}

                    {data && data.Total ? (
                        <Group my="md" position="right">
                            <Pagination
                                page={activePage}
                                onChange={setPage}
                                total={Math.floor(data.Total / 100)}
                                withEdges
                            />
                        </Group>
                    ) : null}
                </Paper>
            </div>
        </React.Fragment>
    );
};
