import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { TelegramBindingDto } from "../types";

export const getTelegramBindings = (onlyConnected?: boolean): Promise<TelegramBindingDto[]> => {
    return axios.get("telegram-bindings", {
        params: {
            onlyConnected,
        },
    });
};

type QueryFnType = typeof getTelegramBindings;

type UseTelegramBindingsOptions = {
    onlyConnected?: boolean;
    config?: QueryConfig<QueryFnType>;
};

export const useTelegramBindings = ({ onlyConnected, config }: UseTelegramBindingsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["telegram-bindings", onlyConnected],
        queryFn: () => getTelegramBindings(onlyConnected),
    });
};
