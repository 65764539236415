import { Button, Drawer, Flex } from "@mantine/core";
import React from "react";
import { Outlet } from "react-router";
import { useAuth } from "../../../lib/auth";
import { UserInfo } from "../components/UserInfo";
import { UserMenu } from "../components/UserMenu";
import { RoleEnum } from "src/features/auth";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@mantine/hooks";
import { IceV2FileSaverDrawer } from "src/features/devices/components/IceV2FileSaverDrawer";
import { UndefinedDeviceFileSaverDrawer } from "./UndefinedDeviceFileSaverDrawer";

type ProfileProps = {};

export const Profile: React.FC<ProfileProps> = () => {
    const { user } = useAuth();

    const { t } = useTranslation();

    const [undefinedDeviceFileSaverOpened, undefinedDeviceFileSaverHandlers] = useDisclosure(false);

    if (!user) {
        return null;
    }

    return (
        <Flex
            align="stretch"
            sx={(theme) => ({
                flexGrow: 1,
                background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
            })}
        >
            <Flex
                direction="column"
                gap="lg"
                py="lg"
                sx={(theme) => ({
                    borderRight: `${1}px solid ${
                        theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
                    }`,
                    maxWidth: 300,
                    flexGrow: 1,
                })}
            >
                <UserInfo user={user} />
                <UserMenu />
                {user.Role === RoleEnum.Admin && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={undefinedDeviceFileSaverHandlers.open} sx={{ width: "250px" }}>
                            {t("Неидентифицированные")}
                        </Button>
                    </div>
                )}
            </Flex>
            <Outlet />
            <Drawer
                opened={undefinedDeviceFileSaverOpened}
                onClose={undefinedDeviceFileSaverHandlers.close}
                title={t("Скачать данные о неидентифицированных устройствах").toString()}
                padding="md"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <UndefinedDeviceFileSaverDrawer onComplete={undefinedDeviceFileSaverHandlers.close} />
            </Drawer>
        </Flex>
    );
};

Profile.displayName = "Profile";
