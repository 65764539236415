import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { SchemaOf } from "yup";
import * as Yup from "yup";
import FormContainer from "src/components/auth/styledFormContainer";
import { Logo } from "src/components/EnergyLogo";
import { useNavigate } from "react-router-dom";
import { Button, TextInput } from "@mantine/core";
import { RegisterCommand } from "../api/register";
import { useAuth } from "src/lib/auth";

const RegisterSchema: SchemaOf<RegisterCommand> = Yup.object({
    Email: Yup.string().min(5, "Длина не менее 5 символов").required("Email не может быть пустым"),
    Password: Yup.string().min(5, "Длина не менее 5 символов").required("Пароль не может быть пустым"),
    UserName: Yup.string().min(5, "Длина не менее 5 символов").required("Имя пользователя не может быть пустым"),
});

interface LoginFormProps {
    onSuccess: () => void;
}

export const RegisterForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
    const navigate = useNavigate();

    const { register } = useAuth();

    const { t } = useTranslation();
    const formik = useFormik<RegisterCommand>({
        initialValues: {
            Email: "",
            Password: "",
            UserName: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            await register(values);
            onSuccess();
        },
    });

    return (
        <FormContainer>
            <div className="flex flex-col items-center">
                <Logo />
                <div className="font-medium text-2xl my-8">{t("Регистрация")}</div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <TextInput
                    id="UserName"
                    name="UserName"
                    className="mb-4"
                    label={t("Имя пользователя")}
                    placeholder={t("Имя пользователя")}
                    value={formik.values.UserName}
                    onChange={formik.handleChange}
                    error={formik.touched.UserName && formik.errors.UserName}
                />
                <TextInput
                    id="Email"
                    name="Email"
                    className="mb-4"
                    label={t("Email")}
                    placeholder={t("Email")}
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    error={formik.touched.Email && formik.errors.Email}
                />
                <TextInput
                    id="Password"
                    name="Password"
                    className="mb-4"
                    label={t("Пароль")}
                    placeholder={t("Пароль")}
                    type="password"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    error={formik.touched.Password && formik.errors.Password}
                />
                <div className="flex justify-between">
                    <Button variant="subtle" onClick={() => navigate("/login")}>
                        {t("К входу")}
                    </Button>
                    <Button type="submit">{t("Создать аккаунт")}</Button>
                </div>
            </form>
        </FormContainer>
    );
};
