import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type UnbindDeviceCommand = {
    UserId: number | null;
    DeviceId: number | null;
};

export const unbindDevice = (command: UnbindDeviceCommand) => {
    return axios.post(`/users/${command.UserId}/devices/${command.DeviceId}/unbind`, command);
};

type UseUnbindDeviceOptions = {
    config?: MutationConfig<typeof unbindDevice>;
};

export const useUnbindDevice = ({ config }: UseUnbindDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка удаления привязки устройства"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users/{UserId}/devices");
            showNotification({
                title: t("Успех!"),
                message: t("Устройство успешно отвязано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: unbindDevice,
    });
};
