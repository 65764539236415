import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, number } from "yup";
import { Button, LoadingOverlay, Select } from "@mantine/core";
import { CreateDeviceChannelCommand, useCreateDeviceChannel } from "src/features/devices/api/createDeviceChannel";
import { useChannels } from "../api/getChannels";
import { useForm, yupResolver } from "@mantine/form";

const CreateDeviceChannelCommandSchema: SchemaOf<CreateDeviceChannelCommand> = object({
    ChannelId: number().required("Идентификатор канала должен быть задан"),
    DeviceId: number().required("Идентификатор устройства должен быть задан"),
});

interface CreateDeviceChannelFormProps {
    setOpen: (value: boolean) => void;
    deviceId: number;
}

export const CreateDeviceChannelForm: React.FC<CreateDeviceChannelFormProps> = ({ setOpen, deviceId }) => {
    const { t } = useTranslation();
    const createDeviceChannelMutation = useCreateDeviceChannel();
    const form = useForm<CreateDeviceChannelCommand>({
        initialValues: {
            DeviceId: deviceId,
            ChannelId: 0,
        },
        validate: yupResolver(CreateDeviceChannelCommandSchema),
    });

    const [deviceModelId, setDeviceModelId] = useState(1);
    const { data: channels, isLoading } = useChannels({
        deviceModelId,
    });

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await createDeviceChannelMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <LoadingOverlay visible={isLoading} />
            <div className="space-y-4 flex-auto">
                <Select
                    value={deviceModelId.toString()}
                    onChange={(value) => {
                        setDeviceModelId(Number(value));
                    }}
                    label={t("Тип устройства")}
                    data={[
                        { value: "1", label: t("МДД") },
                        { value: "2", label: t("КЗ") },
                        { value: "3", label: t("Универсальное") },
                        { value: "4", label: t("Универсальное 2.1") },
                        { value: "5", label: t("Универсальное 2.2") },
                    ]}
                />
                <Select
                    value={form.values.ChannelId.toString()}
                    onChange={(value) => {
                        form.setFieldValue("ChannelId", Number(value));
                    }}
                    label={t("Канал")}
                    data={
                        channels?.map((x) => ({
                            value: x.Id.toString(),
                            label: t(x.Name),
                        })) || []
                    }
                />
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {t("Создать")}
                </Button>
            </div>
        </form>
    );
};
