import React from "react";
import { ReportForm } from "../components/Report/ReportForm";
import { LoadingOverlay } from "@mantine/core";
import { useDevices } from "src/features/devices/api/getDevices";

const ReportView: React.FC = () => {
    const { data: devices, isLoading } = useDevices();

    return (
        <>
            {/* {isMiddle ? <SideNav></SideNav> : <EnergyMobileNav></EnergyMobileNav>} */}
            {devices && <div className="flex flex-1">{devices.length > 0 && <ReportForm devices={devices} />}</div>}
            <LoadingOverlay visible={isLoading} />
        </>
    );
};

export default ReportView;
