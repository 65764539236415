import { Container, ContainerProps, LoadingOverlay, Paper } from "@mantine/core";
import React from "react";
import { PageHeader, PageHeaderProps } from "./PageHeader";
import { PageWrapper } from "./PageWrapper";

type PageProps = ContainerProps & {
    isLoading?: boolean;
    header?: PageHeaderProps;
    withPaper?: boolean;
    children: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({ header, isLoading, children, withPaper, ...other }) => {
    return (
        <PageWrapper>
            <LoadingOverlay loaderProps={{ variant: "bars" }} visible={!!isLoading} />
            <Container pt="xl" px="xl" {...other}>
                {header && <PageHeader {...header} />}
                {withPaper ? (
                    <Paper withBorder mb="xl">
                        {children}
                    </Paper>
                ) : (
                    children
                )}
            </Container>
        </PageWrapper>
    );
};

Page.displayName = "Page";
