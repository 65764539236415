import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateFirmwareCommand = {
    formData: FormData;
};

export const createFirmware = (command: CreateFirmwareCommand): Promise<EntityId<number>> => {
    return axios.post('/firmware/create', command.formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

type UseCreateFirmwareOptions = {
    config?: MutationConfig<typeof createFirmware>;
};

export const useCreateFirmware = ({ config }: UseCreateFirmwareOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("firmwares");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка загрузки прошивки!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("firmwares");
            showNotification({
                title: t("Успех!"),
                message: t("Прошивка успешно добавлена!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createFirmware,
    });
};
