import React from "react";
import MobileChart from "../../../components/Monitoring/MobileChart";
import dayjs from "dayjs";
import { Grid, Title, Text, Divider } from "@mantine/core";
import { ChartChannelData, DeviceChannelDto } from "src/features/devices/types";

interface DeviceCardProps {
    channel: DeviceChannelDto;
    channelChartData?: ChartChannelData;
}

const ChannelCard: React.FC<DeviceCardProps> = ({ channel, channelChartData }) => {
    // function getStatusTagForChannel(channelStatus: ChannelStatus | null) {
    //     switch (channelStatus) {
    //         case ChannelStatus.Normal:
    //             return <Icon fontSize="small" className="fad fa-check-circle text-green-700 mr-2"></Icon>;
    //         case ChannelStatus.WarningHigh:
    //             return <Icon fontSize="small" className="fad fa-arrow-circle-up text-yellow-500 mr-2"></Icon>;
    //         case ChannelStatus.WarningLow:
    //             return <Icon fontSize="small" className="fad fa-arrow-circle-down text-yellow-500 mr-2"></Icon>;
    //         case ChannelStatus.EmergencyHigh:
    //             return <Icon fontSize="small" className="fad fa-arrow-circle-up text-red-500 mr-2"></Icon>;
    //         case ChannelStatus.EmergencyLow:
    //             return <Icon fontSize="small" className="fad fa-arrow-circle-down text-red-500 mr-2"></Icon>;
    //         case ChannelStatus.WrongData:
    //             return <Icon fontSize="small" className="fad fa-times-circle text-red-500 mr-2"></Icon>;
    //         case null:
    //             return <Icon fontSize="small" className="fad fa-question-circle text-gray-500 mr-2"></Icon>;
    //     }
    // }

    return (
        <React.Fragment>
            <div className="p-2">
                <Title className="mb-2" order={6}>
                    {channel.Name}
                </Title>
                <Grid>
                    <Grid.Col span={2}>
                        {/* {getStatusTagForChannel(channel.AlarmStatus)} */}
                        <Text className="flex items-center">
                            {channel.Value?.toFixed(2)}
                            {channel.Value && channel.Unit && ` ${channel.Unit}`}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Text color="gray">
                            {channel.Timestamp && dayjs(channel.Timestamp).format("DD.MM.YYYY HH:mm:ss")}
                        </Text>
                    </Grid.Col>
                </Grid>
                {channelChartData && <MobileChart chartChannelData={channelChartData} isMobile></MobileChart>}
            </div>
            <Divider />
        </React.Fragment>
    );
};

export { ChannelCard };
