import styled from "@emotion/styled";
import React from "react";
import FirmwaresList from "../features/firmware/components/FirmwaresList";
import Dashboard from "./styled/Dashboard";
import tw from "twin.macro";
import { useSelectedFirmware } from "src/features/firmware/hooks/useSelectedFirmware";
import { CurrentFirmwarePanel } from "src/features/firmware/components/CurrentFirmwarePanel";
import { Flex } from "@mantine/core";

const FirmwaresPanel = styled.div({
    ...tw`overflow-hidden z-10 flex justify-center h-full w-full md:w-96 shadow`,
});

interface Props { }

const FirmwareView: React.FC<Props> = () => {
    const selectedFirmware = useSelectedFirmware();

    return (
        <Flex sx={{ flexGrow: 1, height: "100vh", width: "100%" }}>
            <FirmwaresPanel>
                <FirmwaresList />
            </FirmwaresPanel>
            {selectedFirmware ? <CurrentFirmwarePanel firmware={selectedFirmware} /> : null}
        </Flex>
    );
};

export default FirmwareView;
