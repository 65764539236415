import { Badge, Button, Card, Group, LoadingOverlay, NumberInput, Select, Stack, Text, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useServerUrls } from "../api/getServerUrls";
import { useSendParameter } from "../api/sendParameter";
import { useParameterValueValidate } from "../hooks/useParameterValueValidate";
import { DeviceParameterDto, ParameterEnum, ParameterState } from "../types";

const stateLabels = {
    [ParameterState.None]: "Не отправлялось",
    [ParameterState.Queued]: "В очереди",
    [ParameterState.Sent]: "Отправлено",
    [ParameterState.SentWithError]: "Ошибка при отправке",
    [ParameterState.CancelledByTTL]: "Превышено время ожидания",
};

export enum InputType {
    None,
    Text,
    Number,
}

const parametersControlSettings = {
    [ParameterEnum.ShortCircuitCurrentSetting]: {
        inputType: InputType.Number,
        defaultValue: "10",
    },
    [ParameterEnum.ShortCircuitPulseTimeSetting]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.MinimumCurrentSetting]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ServerHost]: {
        inputType: InputType.Text,
        defaultValue: "",
    },
    [ParameterEnum.Restart]: {
        inputType: InputType.None,
        defaultValue: "1",
    },
    [ParameterEnum.CommunicationPeriod]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ShortCircuitCurrentSetting2]: {
        inputType: InputType.Number,
        defaultValue: "10",
    },
    [ParameterEnum.ShortCircuitPulseTimeSetting2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.MinimumCurrentSetting2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.CommunicationPeriod2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.Restart2]: {
        inputType: InputType.None,
        defaultValue: "1",
    },
    [ParameterEnum.ServerHost2]: {
        inputType: InputType.Text,
        defaultValue: "",
    },
    [ParameterEnum.MaxOperTime]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.DurationPeriod]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.OscilloscopeDuration]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.FirstSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.SecondSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ThirdSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.Invert]: {
        inputType: InputType.Number,
        defaultValue: "0",
    },
};

type RemoteControlCardProps = {
    deviceId: number;
    parameter: DeviceParameterDto;
};

export const RemoteControlCard: React.FC<RemoteControlCardProps> = ({ parameter, deviceId }) => {
    const { t } = useTranslation();

    const { data: serverUrls, isLoading } = useServerUrls();
    const { validateAsync } = useParameterValueValidate({ deviceParameter: parameter });

    const sendParameterMutation = useSendParameter();
    const form = useForm({
        initialValues: {
            deviceId,
            parameterId: parameter.ParameterId,
            value: parameter.Value ?? parametersControlSettings[parameter.ParameterId as ParameterEnum].defaultValue,
        },
    });

    if (isLoading) {
        return <LoadingOverlay visible={true} />;
    }

    return (
        <Card
            withBorder
            component="form"
            onSubmit={form.onSubmit(async (values) => {
                const error = await validateAsync(values.value);
                if (error) {
                    form.setFieldError("value", error);
                    return;
                }

                await sendParameterMutation.mutateAsync({
                    ...values,
                });
            })}
        >
            <Group position="apart">
                <Stack
                    sx={{
                        width: "fit-content",
                        maxWidth: "50%",
                    }}
                >
                    <Title order={4}>{t(parameter.Name).toString()}</Title>
                    <Group position="apart">
                        <Badge radius="xs" size="sm" color="dark" variant="filled">
                            {t(stateLabels[parameter.State]).toString()}
                        </Badge>
                        {parameter.Timestamp ? (
                            <Text size="sm" color="gray">
                                {t("Дата последней отправки").toString()}:{" "}
                                {dayjs(parameter.Timestamp).format("DD.MM.YYYY HH:mm")}
                            </Text>
                        ) : (
                            <div />
                        )}
                    </Group>
                </Stack>
                <Group>
                    {parametersControlSettings[parameter.ParameterId as ParameterEnum].inputType === InputType.Text && (
                        <Select
                            disabled={parameter.Disabled}
                            withinPortal={true}
                            data={serverUrls?.map((x) => x.Url) || []}
                            {...form.getInputProps("value")}
                        />
                    )}
                    {parametersControlSettings[parameter.ParameterId as ParameterEnum].inputType ===
                        InputType.Number && (
                        <NumberInput
                            disabled={parameter.Disabled}
                            max={parameter.MaxValue!}
                            min={parameter.MinValue!}
                            value={Number(form.getInputProps("value").value)}
                            onChange={(value) => form.getInputProps("value").onChange(value?.toString())}
                        />
                    )}
                    <Button disabled={parameter.State === ParameterState.Queued || parameter.Disabled} type="submit">
                        {t("Отправить").toString()}
                    </Button>
                </Group>
            </Group>
        </Card>
    );
};

RemoteControlCard.displayName = "RemoteControlCard";
