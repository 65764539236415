import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import "./dayjsru";
import "./i18n";
import App from "./App";
// import { worker } from "./mocks/browser";

// worker.start();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
