import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { ChannelDto } from "../types";

export const getChannels = (deviceModelId: number): Promise<ChannelDto[]> => {
    return axios.get(`/channels/?deviceModelId=${deviceModelId}`);
};

type QueryFnType = typeof getChannels;

type UseChannelsOptions = {
    deviceModelId: number;
    config?: QueryConfig<QueryFnType>;
};

export const useChannels = ({ deviceModelId, config }: UseChannelsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["channels", deviceModelId],
        queryFn: () => getChannels(deviceModelId),
    });
};
