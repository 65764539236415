import React, { useRef, useEffect } from "react";
import YandexMap from "../../components/Map/YandexMap";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { DeviceDto } from "src/features/devices/types";
import PositionMarker from "../Map/PositionMarker";
import { useYMaps } from "@pbe/react-yandex-maps";

const MapContainer = styled.div({
    ...tw`h-full w-full`,
});

interface SelectPositionMapProps {
    onClick: (value: [number | null | undefined, number | null | undefined]) => void;
    onChange: (value: [number | null | undefined, number | null | undefined]) => void;
    device: DeviceDto;
}

const SelectPositionMap: React.FC<SelectPositionMapProps> = ({ onClick, onChange, device }) => {
    const devicesMarker = useRef<PositionMarker | null>(null);
    const ymap = useRef<YandexMap>();
    const ymaps = useYMaps();
    useEffect(() => {
        if (ymaps && ymaps.Map) {
            ymap.current = new YandexMap(ymaps, "select-position-ymap", {
                center: [54.194442, 37.599728],
                zoom: 10,
                maxZoom: 18,
            });

            ymap.current.addEvent("click", (e: any) => {
                const position = e.get("coords");
                onClick(position);
            });
        }

        return () => {
            if (ymap.current) {
                ymap.current.destroy();
            }
        };
    }, [ymaps]);

    function onUpdateGeometry(value: any) {
        onChange(value?.originalEvent?.newCoordinates ?? [device.Latitude, device.Longitude]);
    }

    useEffect(() => {
        devicesMarker.current?.stopEditing(onUpdateGeometry);
        devicesMarker.current?.hide();
        if (ymap.current && device.Latitude && device.Longitude) {
            devicesMarker.current = new PositionMarker(ymap.current, device, () => {});
            devicesMarker.current.show();
            devicesMarker.current.focus();
            devicesMarker.current.startEditing(onUpdateGeometry);
        }

        return () => {
            devicesMarker.current?.stopEditing(onUpdateGeometry);
            devicesMarker.current?.hide();
        };
    }, [ymaps, device.Latitude, device.Longitude, device.Name, device.DeviceModel]);

    return <MapContainer id="select-position-ymap" />;
};

export default SelectPositionMap;
