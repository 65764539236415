import { axios } from "src/lib/axios";
import { LoginResponse } from "../types";

export type RegisterCommand = {
    Email: string;
    UserName: string;
    Password: string;
};

export async function register(form: RegisterCommand): Promise<LoginResponse> {
    return await axios.post("/users/register", form);
}
