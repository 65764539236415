import { ChannelThresholdDto } from "../../notifications/types";

export enum ShortCircuitEventEnum {
    NormalCurrent,
    ShortCircuitCurrent,
    LowCurrent,
}

export interface ShortCircuitEventDto {
    Timestamp: string;
    Current: number;
    Event: ShortCircuitEventEnum;
}

export enum ParameterState {
    None,
    Queued,
    Sent,
    SentWithError,
    CancelledByTTL,
}

export enum DeviceModelEnum {
    RDM = 1,
    RDMShortCircuit = 2,
    RDMUniversal = 3,
    RDMUniversal2 = 4,
    RDMUniversal22 = 5,
}

export type DeviceModelDto = {
    Id: number;
    Name: string;
};

export enum ParameterValidationStrategyEnum {
    NumberValidationStrategy,
    ServerUrlValidationStrategy,
}

export type DeviceParameterDto = {
    Id: number;
    Name: string;
    Timestamp: string | null;
    Value: string | null;
    State: ParameterState;
    ParameterId: number;
    ValidationStrategy: ParameterValidationStrategyEnum;
    MinValue: number | null;
    MaxValue: number | null;
    Disabled: boolean;
};

export type DeviceChannelDto = {
    Id: number;
    Name: string;
    Comment: string | null;
    Timestamp: string | null;
    Value: number | null;
    Unit?: string;
    ChannelThresholds: ChannelThresholdDto[];
    CurrentChannelThreshold: ChannelThresholdDto;
};

export type DeviceDto = {
    Id: number;
    Name: string;
    Comment: string;
    Latitude?: number | null;
    Longitude?: number | null;
    CCID: string;
    ICCIDModbus64?: string;
    MACModbusHEX?: string;
    VersionModbus?: number;
    IceV2CalculationEnabled: boolean;
    DeviceModel: DeviceModelEnum;
    DeviceParameters: DeviceParameterDto[];
    DeviceChannels: DeviceChannelDto[];
    AutoTimeZoneDetection: boolean;
    AutoTimeZoneSeasonChanging: boolean;
    SummerTimeZoneChanging?: Date | null;
    WinterTimeZoneChanging?: Date | null;
    TimeZone: number | null;
    GetOscilloscope: boolean;
    TimeSynchronization: boolean;
};

export type ChartChannelData = {
    Id: number;
    Name: string;
    Timestamps: string[];
    Values: number[];
    Unit: string;
    FormattedValues: string[];
};

export type ChartData = {
    Id: number;
    StartDt: Date | string | null;
    EndDt: Date | string | null;
    ChartChannelData: ChartChannelData[];
};

export type OscilloscopeDto = {
    Id: number;
    Type: OscilloscopeTypeEnum;
    State: OscilloscopeStateEnum;
    MeasurementsTimestamp: Date;
    Frequency: number;
    TotalMeasurements: number;
    ReceivedMeasurements: number;
    Data: number[];
};

export enum OscilloscopeTypeEnum {
    Current = 1,
}

export enum OscilloscopeStateEnum {
    Starting = 0,
    Processing = 1,
    Success = 2,
    Cancelled = 3,
    Error = 4,
}

export interface IceOffsetAngleDto {
    DeviceId: number;
    OffsetAngle?: number;
}

export enum IceV2ConstantComponentStatusEnum {
    None,
    Calculated,
    CalculationError,
    SetManually,
}

export interface IceV2ConstantComponentDto {
    IceV2ConstantComponentWindow?: number;
    IceV2ConstantComponentWindowStartDate?: string;
    IceV2ConstantComponent?: number;
    IceV2ConstantComponentStatus: IceV2ConstantComponentStatusEnum;
    IceV2ConstantComponentIgnoreIceTemperature?: boolean;
}

export interface IceV2ParametersDto {
    InstallationDate?: string;
    IceV2ZeroGDrift?: number;
    IceV2Temperature0?: number;
    IceV2LengthReal?: number;
    IceV2LengthReduced?: number;
    IceV2ElasticModulus?: number;
    IceV2Gamma0?: number;
    IceV2CrossSectionalArea?: number;
    IceV2Weight?: number;
    IceV2Diameter?: number;
    IceV2Sigma0?: number;
    IceV2Tension?: number;
    IceV2ThermalExpansionCoefficient?: number;
    IceV2ControlStartTemperature?: number;
    IceV2ControlStopTemperature?: number;
    IceV2ControlStopIcePerMeter?: number;
    IceV2MinimumIceTemperature?: number;
    IceV2MovingAverageWindow?: number;
}

export interface IceParametersDto {
    DeviceId: number;
    Gamma?: number;
    Length?: number;
    ElasticModulus?: number;
    ThermalExpansionCoefficient?: number;
    Sigma0?: number;
    Temperature0?: number;
    CrossSectionalArea?: number;
    Weight?: number;
    Diameter?: number;
    FilterCoefficient?: number;
}

export interface SaveIceV2ParametersCommand {
    DeviceId: number;
    IceV2ConstantComponentWindow?: number;
    IceV2ConstantComponentWindowStartDate?: string;
    IceV2ConstantComponent?: number;
    InstallationDate?: string;
    IceV2ZeroGDrift?: number;
    IceV2Temperature0?: number;
    IceV2LengthReal?: number;
    IceV2LengthReduced?: number;
    IceV2ElasticModulus?: number;
    IceV2Gamma0?: number;
    IceV2CrossSectionalArea?: number;
    IceV2Weight?: number;
    IceV2Diameter?: number;
    IceV2Sigma0?: number;
    IceV2Tension?: number;
}

export enum ParameterEnum {
    ShortCircuitCurrentSetting = 1,
    ShortCircuitPulseTimeSetting = 2,
    MinimumCurrentSetting = 3,
    ServerHost = 4,
    Restart = 5,
    CommunicationPeriod = 6,
    ShortCircuitCurrentSetting2 = 7,
    ShortCircuitPulseTimeSetting2 = 8,
    MinimumCurrentSetting2 = 9,
    CommunicationPeriod2 = 10,
    Restart2 = 11,
    ServerHost2 = 12,
    MaxOperTime = 13,
    DurationPeriod = 14,
    OscilloscopeDuration = 15,
    FirstSetpoint = 16,
    SecondSetpoint = 17,
    ThirdSetpoint = 18,
    Invert = 19,
}

export type ServerUrlDto = {
    Id: number;
    Url: string;
};

export interface IceV2CalculationValueDto {
    Id: number;
    DeviceId: number;
    Step: IceV2StepEnum;
    Timestamp: string;
    PreviousTimestamp: string | null;
    Temperature: number | null;
    Angle: number | null;
    AngleCalibrated: number | null;
    SigmaCalculated: number | null;
    AngleCalculated: number | null;
    AngleActual: number | null;
    AngleReal: number | null;
    SigmaCalibrated: number | null;
    SigmaAverage: number | null;
    SigmaDeviation: number | null;
    SigmaDeviationPrime: number | null;
    SigmaDeviationAverage: number | null;
    State: IceV2StateEnum | null;
    SigmaPrimeCalibrated: number | null;
    TemperaturePrime: number | null;
    Gamma: number | null;
    IcePerMeter: number | null;
    IcePerSpan: number | null;
    IsNormal: boolean;
    IsControl: boolean;
}

export enum IceV2StepEnum {
    FirstStepProcessing = 1,
    SecondStepProcessing = 2,
    ThirdStepProcessing = 3,
    Completed = 4,
}

export enum IceV2StateEnum {
    Normal = 0,
    Control = 1,
}

export interface ChannelDto {
    Id: number;
    Name: string;
    Comment: string;
    MinValue: number | null;
    MaxValue: number | null;
    Unit: string | null;
    IsComputed: boolean | null;
}

export interface ParameterDto {
    Id: number;
    Name: string;
    ValidationStrategy: ParameterValidationStrategyEnum;
    MinValue: number | null;
    MaxValue: number | null;
    TimeToSend: number | null;
    Disabled: boolean;
}

export interface BreakDetectionParametersDto {
    BreakDetectionEnabled: boolean;
    BreakDetectionMovingAverageWindow: number | null;
    BreakDetectionAngleDeviation: number | null;
}

export interface DimensionParametersDto {
    DimensionEnabled: boolean;
    Height: number | null;
    LengthDimension: number | null;
    DimensionDate?: string;
    DimensionTime?: string;
    DimensionState: DimensionStateEnum | null;
}

export enum DimensionStateEnum {
    None = 0,
    Waiting = 1,
    Calculated = 2,
    CalculationError = 3,
}

export interface UserDeviceDto {
    DeviceId: number;
    UserId: number;
}

export interface FirmwareDeviceDto {
    Id: number;
    DeviceId: number;
    FirmwareId: number;
    Status: FirmwareEnum;
    StartDate: Date;
    EndDate: Date;
    PageNumber: number;
    Pages: number;
    Pause: boolean;
}

export enum FirmwareEnum {
    NotRequired = 0,
    Waiting = 1,
    Running = 2,
    Sending = 3,
    Finishing = 4,
    Success = 5,
    Error = 6,
    CancelledAutomatically = 7,
    CancelledManually = 8,
}
