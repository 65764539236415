import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { ParameterEnum } from "../types";

export interface SendDeviceParameterCommand {
    deviceId: number;
    parameterId: ParameterEnum;
    value: string;
}

export const sendParameter = (data: SendDeviceParameterCommand) => {
    return axios.post(`devices/${data.deviceId}/device-parameters/send`, data);
};

type UseSendParameterOptions = {
    config?: MutationConfig<typeof sendParameter>;
};

export const useSendParameter = ({ config }: UseSendParameterOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при отправке параметра!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("");
            showNotification({
                title: t("Успех!"),
                message: t("Параметр отправлен успешно!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: sendParameter,
    });
};
