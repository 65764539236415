import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type BindDeviceCommand = {
    UserId?: number | null;
    DeviceId?: number | null;
};

export const bindDevice = (command: BindDeviceCommand) => {
    return axios.post(`/users/${command.UserId}/devices/${command.DeviceId}/bind`, command);
};

type UseBindDeviceOptions = {
    config?: MutationConfig<typeof bindDevice>;
};

export const useBindDevice = ({ config }: UseBindDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка привязки устройства"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Устройство успешно привязано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: bindDevice,
    });
};
