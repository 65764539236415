import React from "react";
import { IconBolt } from "@tabler/icons-react";

interface Props {
    iconOnly?: boolean;
}

export const Logo: React.FC<Props> = ({ iconOnly = false }) => {
    return (
        <div className="flex items-center">
            <div className="group flex items-center p-2 rounded bg-yellow-50 cursor-pointer">
                <IconBolt className="text-yellow-500 mr-1" />
            </div>
            {!iconOnly && (
                <div
                    style={{ fontFamily: "'Rubik', sans-serif" }}
                    className="ml-4 flex items-center font-medium text-base text-gray-700"
                >
                    {/* <div className="mr-1">Service</div> */}
                    <div>E-Energy</div>
                </div>
            )}
        </div>
    );
};
