import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type deleteOscilloscopeCommand = {
    DeviceId:       number;
    OscilloscopeId: number;
};

export const deleteOscilloscope = (command: deleteOscilloscopeCommand) => {
    return axios.delete(`/devices/${command.DeviceId}/oscilloscope/${command.OscilloscopeId}`);
};

type UseCreateFirmwareOptions = {
    config?: MutationConfig<typeof deleteOscilloscope>;
};

export const useDeleteOscilloscope = ({ config }: UseCreateFirmwareOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices/{DeviceId}/oscilloscopes");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при отправке запроса!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices/{DeviceId}/oscilloscopes");
            showNotification({
                title: t("Успех!"),
                message: t("Запрос успешно отправлен!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: deleteOscilloscope,
    });
};
