import styled from "@emotion/styled";
import React from "react";
import UsersList from "../features/users/components/UsersList";
import Dashboard from "./styled/Dashboard";
import tw from "twin.macro";
import { useSelectedUser } from "../features/users/hooks/useSelectedUser";
import { CurrentUserPanel } from "src/features/users/components/CurrentUserPanel";

const UsersPanel = styled.div({
    ...tw`overflow-hidden z-10 flex justify-center h-full w-full md:w-96 shadow`,
});

interface Props {}

const AdminView: React.FC<Props> = () => {
    const selectedUser = useSelectedUser();

    return (
        <Dashboard>
            <UsersPanel>
                <UsersList />
            </UsersPanel>
            {selectedUser ? <CurrentUserPanel user={selectedUser} /> : null}
        </Dashboard>
    );
};

export default AdminView;
