import styled from "@emotion/styled";
import tw from "twin.macro";

const FormContainer = styled.div({
    ...tw`flex bg-white flex-col box-border rounded shadow p-8 `,
    "@media (max-width: 1000px)": {
        width: "90%",
    },
    width: "450px",
});

export default FormContainer;
