import { Button } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider } from "formik";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { DeviceDto } from "src/features/devices/types";
import { getIceV2File, IIceV2Settings } from "src/api/iceV2";

type IceV2FileSaverDrawerProps = {
    device: DeviceDto | undefined;
    onComplete: () => void;
};

export const IceV2FileSaverDrawer: React.FC<IceV2FileSaverDrawerProps> = ({ device, onComplete }) => {
    const { t } = useTranslation();

    const formik = useFormik<IIceV2Settings>({
        initialValues: {
            DeviceId: device ? device.Id : 1,
            StartDt: dayjs().subtract(2, "day").toDate(),
            EndDt: dayjs().toDate(),
        },

        onSubmit: async (values) => {
            const filename = `${device?.Name} ${device?.CCID} ice values`;
            await getIceV2File(values, `${filename}_${dayjs().format("YYYYMMDDHHmm")}`);
            onComplete();
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div style={{ marginBottom: "16px" }}>
                    <DatePicker
                        value={formik.values.StartDt}
                        maxDate={formik.values.EndDt}
                        inputFormat="DD.MM.YYYY"
                        onChange={(date) => formik.setFieldValue("StartDt", date)}
                        label={t("Начало интервала")}
                    />
                </div>

                <div style={{ marginBottom: "16px" }}>
                    <DatePicker
                        value={formik.values.EndDt}
                        minDate={formik.values.StartDt}
                        inputFormat="DD.MM.YYYY"
                        onChange={(date) => formik.setFieldValue("EndDt", date)}
                        label={t("Конец интервала")}
                    />
                </div>

                <div>
                    <Button type="submit">{t("Скачать")}</Button>
                </div>
            </form>
        </FormikProvider>
    );
};

IceV2FileSaverDrawer.displayName = "IceV2FileSaverDrawer";
