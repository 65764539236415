import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateDeviceCommand = {
    Name: string;
    Comment: string;
    Latitude?: number | null;
    Longitude?: number | null;
    CCID: string;
    DeviceModelId: number;
    AutoTimeZoneDetection: boolean;
    AutoTimeZoneSeasonChanging: boolean;
    SummerTimeZoneChanging?: Date | null;
    WinterTimeZoneChanging?: Date | null;
    TimeZone: number | null;
};

export const createDevice = (command: CreateDeviceCommand): Promise<EntityId<number>> => {
    console.log(command);
    return axios.post(`/devices`, command);
};

type UseCreateDeviceOptions = {
    config?: MutationConfig<typeof createDevice>;
};

export const useCreateDevice = ({ config }: UseCreateDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("devices");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания устройства"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Устройство успешно создано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createDevice,
    });
};
