import download from "downloadjs";
import { http } from "src/lib/http";

export interface IOscilloscopeFileSettings {
    DeviceId: number;
    OscilloscopeId: number;
}

export interface IOscilloscopeFileRequest {
    OscilloscopeFileSettings: IOscilloscopeFileSettings;
    Filename: string;
}

export async function getOscilloscopeFile(oscilloscopeFileRequest: IOscilloscopeFileRequest) {
    const { data } = await http.post("/oscilloscopeFile", oscilloscopeFileRequest, {
        responseType: "blob",
    });
    download(data, oscilloscopeFileRequest.Filename, "application/octet-stream");
}
