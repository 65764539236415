import { useTranslation } from "react-i18next";
import { Flex, Paper, Tabs } from "@mantine/core";
import { UserDto } from "src/features/auth";
import React, { useMemo, useState } from "react";
import { useDevices } from "src/features/devices/api/getDevices";
import { DeviceDto } from "src/features/devices/types";
import { DataTable } from "mantine-datatable";
import { DeviceModelEnum } from "src/features/devices/types";
import { useIECUserDevices } from "../api/getIECUserDevices";

type ObjectsSettingsProps = {
    user: UserDto;
};

export const ObjectSettings: React.FC<ObjectsSettingsProps> = ({ user }) => {
    const { t } = useTranslation();
    const { data: devices } = useDevices();

    const devicesDict = useMemo(
        () => devices?.reduce<Record<number, DeviceDto>>((acc, value) => ({ ...acc, [value.Id]: value }), {}),
        [devices]
    );

    const [activePage, setPage] = useState(1);
    const { data } = useIECUserDevices({
        request: {
            UserId: user.Id,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    const rdmData = data?.Page.filter((item) => item.DeviceModel == DeviceModelEnum.RDM);
    const shortCircuitData = data?.Page.filter((item) => item.DeviceModel == DeviceModelEnum.RDMShortCircuit);
    const rdmUniversalData = data?.Page.filter((item) => item.DeviceModel == DeviceModelEnum.RDMUniversal);
    const rdmUniversal2Data = data?.Page.filter((item) => item.DeviceModel == DeviceModelEnum.RDMUniversal2);
    const rdmUniversal22Data = data?.Page.filter((item) => item.DeviceModel == DeviceModelEnum.RDMUniversal22);

    return (
        <Tabs variant="outline" orientation="vertical" defaultValue="MDD">
            <Flex style={{ width: "100%" }}>
                <Paper className="mb-4" p="md" shadow="sm" style={{ marginRight: "1rem" }}>
                    <Tabs.List>
                        <Tabs.Tab value="MDD">{t("МДД")}</Tabs.Tab>
                        <Tabs.Tab value="ShortCircuit">{t("КЗ")}</Tabs.Tab>
                        <Tabs.Tab value="Universal">{t("Универсальное")}</Tabs.Tab>
                        <Tabs.Tab value="Universal2">{t("Универсальное 2.1")}</Tabs.Tab>
                    </Tabs.List>
                </Paper>
                <Paper className="mb-4" p="md" shadow="sm" style={{ flex: 1 }}>
                    <Tabs.Panel value="MDD">
                        {devicesDict && (
                            <DataTable
                                minHeight={150}
                                withBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                records={rdmData}
                                columns={[
                                    {
                                        accessor: "DeviceId",
                                        title: "Устройство",
                                        width: 100,
                                        render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                    },
                                    {
                                        accessor: "SerialNumber",
                                        title: "Порядковый номер",
                                        textAlignment: "right",
                                        render: (rdmData) => {
                                            return rdmData.SerialNumber;
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="ShortCircuit">
                        {devicesDict && (
                            <DataTable
                                minHeight={150}
                                withBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                records={shortCircuitData}
                                columns={[
                                    {
                                        accessor: "DeviceId",
                                        title: "Устройство",
                                        width: 100,
                                        render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                    },
                                    {
                                        accessor: "SerialNumber",
                                        title: "Порядковый номер",
                                        textAlignment: "right",
                                        render: (shortCircuitData) => {
                                            return shortCircuitData.SerialNumber;
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="Universal">
                        {devicesDict && (
                            <DataTable
                                minHeight={150}
                                withBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                records={rdmUniversalData}
                                columns={[
                                    {
                                        accessor: "DeviceId",
                                        title: "Устройство",
                                        width: 100,
                                        render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                    },
                                    {
                                        accessor: "SerialNumber",
                                        title: "Порядковый номер",
                                        textAlignment: "right",
                                        render: (rdmUniversalData) => {
                                            return rdmUniversalData.SerialNumber;
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="Universal2">
                        {devicesDict && (
                            <DataTable
                                minHeight={150}
                                withBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                records={rdmUniversal2Data}
                                columns={[
                                    {
                                        accessor: "DeviceId",
                                        title: "Устройство",
                                        width: 100,
                                        render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                    },
                                    {
                                        accessor: "SerialNumber",
                                        title: "Порядковый номер",
                                        textAlignment: "right",
                                        render: (rdmUniversal2Data) => {
                                            return rdmUniversal2Data.SerialNumber;
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="Universal22">
                        {devicesDict && (
                            <DataTable
                                minHeight={150}
                                withBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                records={rdmUniversal22Data}
                                columns={[
                                    {
                                        accessor: "DeviceId",
                                        title: "Устройство",
                                        width: 100,
                                        render: ({ DeviceId }) => devicesDict[DeviceId].Name,
                                    },
                                    {
                                        accessor: "SerialNumber",
                                        title: "Порядковый номер",
                                        textAlignment: "right",
                                        render: (rdmUniversal22Data) => {
                                            return rdmUniversal22Data.SerialNumber;
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Tabs.Panel>
                </Paper>
            </Flex>
        </Tabs>
    );
};

ObjectSettings.displayName = "TCSettings";
