import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type ToggleChannelThresholdNotificationCommand = {
    ChannelThresholdId: number;
    TelegramBindingId: number;
};

export const toggleChannelThresholdNotification = (data: ToggleChannelThresholdNotificationCommand) => {
    return axios.post(`thresholds/${data.ChannelThresholdId}/notifications`, data);
};

type UseToggleChannelThresholdNotificationOptions = {
    channelId: number;
    config?: MutationConfig<typeof toggleChannelThresholdNotification>;
};

export const useToggleChannelThresholdNotification = ({
    channelId,
    config,
}: UseToggleChannelThresholdNotificationOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t(""),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["channels/{channelId}/thresholds", channelId]);
            showNotification({
                title: t("Успех!"),
                message: t(""),
                color: "teal",
            });
        },
        ...config,
        mutationFn: toggleChannelThresholdNotification,
    });
};
