import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { UserDto } from "../types";

export async function getUser(): Promise<UserDto> {
    return await axios.get("/users/me");
}

type QueryFnType = typeof getUser;

type UseCurrentUserOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useCurrentUser = ({ config }: UseCurrentUserOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["users-me"],
        queryFn: getUser,
    });
};
