import React from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, number, date } from "yup";
import { NumberInput, Button, Stack } from "@mantine/core";
import { IceV2ParametersDto } from "src/features/devices/types";
import { useForm, yupResolver } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { SaveIceV2ParametersCommand, useSaveIceV2Parameters } from "../api/saveIceV2Parameters";
import { safeParseDate } from "../../../utils/date";

const SaveIceV2ParametersCommandSchema: SchemaOf<SaveIceV2ParametersCommand> = object({
    DeviceId: number().required(),
    IceV2ZeroGDrift: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2Temperature0: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2LengthReal: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2LengthReduced: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ElasticModulus: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2CrossSectionalArea: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2Weight: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2Diameter: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2Tension: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ThermalExpansionCoefficient: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    InstallationDate: date()
        .transform((value) => (!(value instanceof Date) ? undefined : value))
        .optional(),
    IceV2MinimumIceTemperature: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ControlStartTemperature: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ControlStopIcePerMeter: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2ControlStopTemperature: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
    IceV2MovingAverageWindow: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional(),
});

interface IceV2ParametersDrawerProps {
    setOpen: (value: boolean) => void;
    deviceId: number;
    iceV2Parameters: IceV2ParametersDto;
}

export const IceV2ParametersDrawer: React.FC<IceV2ParametersDrawerProps> = ({ setOpen, deviceId, iceV2Parameters }) => {
    const { t } = useTranslation();
    const saveMutation = useSaveIceV2Parameters();
    const form = useForm<SaveIceV2ParametersCommand>({
        initialValues: {
            DeviceId: deviceId,
            ...iceV2Parameters,
            InstallationDate: safeParseDate(iceV2Parameters.InstallationDate),
        },
        validate: yupResolver(SaveIceV2ParametersCommandSchema),
    });

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    return (
        <form
            onSubmit={form.onSubmit(async (values) => {
                await saveMutation.mutateAsync(values);
                handleClose();
            })}
        >
            <div className="flex-1 overflow-y-scroll">
                <div>
                    <Stack>
                        <DatePicker
                            label={t("Дата установки")}
                            inputFormat="DD.MM.YYYY"
                            {...form.getInputProps("InstallationDate")}
                        />
                        <NumberInput
                            label={t("Дрифт Zero-G")}
                            step={0.005}
                            precision={8}
                            {...form.getInputProps("IceV2ZeroGDrift")}
                        />
                        <NumberInput
                            label={t("Температура при начальных условиях")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2Temperature0")}
                        />
                        <NumberInput
                            label={t("Длина реальная")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2LengthReal")}
                        />
                        <NumberInput
                            label={t("Длина приведенная")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2LengthReduced")}
                        />
                        <NumberInput
                            label={t("Модуль упругости")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2ElasticModulus")}
                        />
                        <NumberInput
                            label={t("Площадь сечения")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2CrossSectionalArea")}
                        />
                        <NumberInput
                            label={t("Вес 1м провода")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2Weight")}
                        />
                        <NumberInput
                            label={t("Диаметр провода")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2Diameter")}
                        />
                        <NumberInput
                            label={t("Горизонтальная проекция")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2Tension")}
                        />
                        <NumberInput
                            label={t("Модуль температурного расширения")}
                            step={0.05}
                            precision={8}
                            {...form.getInputProps("IceV2ThermalExpansionCoefficient")}
                        />
                        <NumberInput
                            label={t("Окно расчета среднего 'Отклонения сигма'")}
                            step={60}
                            precision={0}
                            {...form.getInputProps("IceV2MovingAverageWindow")}
                        />
                        <NumberInput
                            label={t("Минимальная температура образования гололёда")}
                            step={1}
                            precision={4}
                            {...form.getInputProps("IceV2MinimumIceTemperature")}
                        />
                        <NumberInput
                            label={t("Температура начала контроля гололедообразования")}
                            step={1}
                            precision={4}
                            {...form.getInputProps("IceV2ControlStartTemperature")}
                        />
                        <NumberInput
                            label={t("Температура завершения контроля гололёда")}
                            step={1}
                            precision={4}
                            {...form.getInputProps("IceV2ControlStopTemperature")}
                        />
                        <NumberInput
                            label={t("Вес завершения контроля")}
                            step={0.05}
                            precision={4}
                            {...form.getInputProps("IceV2ControlStopIcePerMeter")}
                        />
                    </Stack>
                    <div className="space-x-3 mt-8 flex justify-end">
                        <Button onClick={handleClose}>{t("Отмена")}</Button>
                        <Button type="submit">{t("Сохранить")}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};
