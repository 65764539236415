import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { FirmwareDeviceDto } from "../types";

export interface GetFirmwareDevicesQuery extends PagedRequest {
    FirmwareId: number;
}

export const getFirmwareDevices = (request: GetFirmwareDevicesQuery): Promise<PagedCollection<FirmwareDeviceDto>> => {
    return axios.post(`firmware/${request.FirmwareId}/devices`, request);
};

type QueryFnType = typeof getFirmwareDevices;

type UseFirmwareDevicesOptions = {
    request: GetFirmwareDevicesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useFirmwareDevices = ({ request, config }: UseFirmwareDevicesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["firmware/{FirmwareId}/devices", request],
        queryFn: () => getFirmwareDevices(request),
    });
};
